import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-peanut-butter-solution-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1985 children's film, The Peanut Butter Solution"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 18</h1>
                    <h2>The Peanut Butter Solution</h2>
                    <h3>September 22, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/the-peanut-butter-solution">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:05.581)
You&#39;re listening to Bring Me the Axe. I&#39;m Brian White, one half of this morbid equation, and I&#39;m joined by my co-host and actual brother Dave White. Dave, how you doing?</p>
<p>Dave! (00:16.143)
Well, I&#39;ll tell you, I had a first time we tried to record this last week. I had a pretty good bit going for this salutation. And I was thinking, well, maybe I could just do it again, but that felt really disingenuous. So here we are.</p>
<p>Bryan! (00:24.391)
You did. I&#39;m so sad we lost it.</p>
<p>Bryan! (00:31.877)
it. Yeah, if I recall, it was about Lauren Bobert jerking her boyfriend off at the Beetlejuice show. I know is is there no upper limit to how weird <em>**</em> can get like it&#39;s just every time I turn around, I&#39;m like, well, things just can&#39;t get any weirder than that and then Lauren Bobert jerks her boyfriend off at a at a Beetlejuice play.</p>
<p>Dave! (00:38.686)
It did. It did. That is my favorite story of the... It might be my favorite story of the year.</p>
<p>Dave! (00:55.286)
Yeah. And it&#39;s like, it&#39;s kind of like, really Colorado? This is your girl? This is the one you want? Alright.</p>
<p>Bryan! (01:02.685)
Yeah, and we will and we will never be rid of her. No, no. Yeah, so good God. Yeah. So, listener, this is the second attempt. We&#39;re making it at this at this episode cuz we tried to deliver this one on time. You know, for Monday and shit just conspired against us to make it to make it not happen. So, here we are. Take two. We&#39;re gonna give it to you cuz we&#39;re just that dedicated.</p>
<p>Dave! (01:06.533)
Oh god.</p>
<p>Bryan! (01:29.293)
I&#39;ve been away. I basically I woke up really, really early before the sun was even up. I got on an airplane to get all the way back up here. And here we are. And I&#39;ve got a bottle of cheer wine to get me through.</p>
<p>Dave! (01:42.379)
Is that like toilet wine? What is that?</p>
<p>Bryan! (01:43.925)
It&#39;s a it&#39;s a it&#39;s a South it&#39;s a North Carolina beverage. But you can only you can only apparently can look at there. It&#39;s delightful. I have a ritual whenever I get off the plane. It&#39;s the first thing I do is get me a bottle of cheer wine because it&#39;s delicious tastes kind of like I don&#39;t I don&#39;t know because it&#39;s southern actually I think it&#39;s derived from cherries. And so it just I don&#39;t know it just kind of follows I guess.</p>
<p>Dave! (01:49.919)
It&#39;s disgusting.</p>
<p>Dave! (01:58.722)
Why is it called sheer wine?</p>
<p>Dave! (02:09.038)
Because it really does make me think toilet wine.</p>
<p>Bryan! (02:14.027)
Well, it&#39;s not title of wine. I it&#39;s really, really quite, quite delicious. Yeah, yeah, it&#39;s like it&#39;s a soft drink. Yep. Hey, so we practically grew up in neighborhood video stores and the steady diet of utter garbage that those shops provided us with continues unabated to this day. There&#39;s no one else I enjoyed chopping it up with more about trash movies and Dave.</p>
<p>Dave! (02:18.61)
is a carbonated cherry beverage.</p>
<p>Bryan! (02:36.205)
Just before we get into it, here&#39;s a little housekeeping. If you want to keep up with us between episodes, you can also find us pretty much on all the socials, except for Twitter, because fuck that shit, Elon Musk can have it. And he can eat my dick. You can find us basically everywhere else. Bring Me the Axe Horror Podcast. You know, we got a good deal of action hanging out on Instagram. There&#39;s our main account. Dave&#39;s got an account also at That Queer Wolf.</p>
<p>Dave! (03:01.622)
Yeah, I&#39;ll tell you what, I actually joined the internet for this specific purpose, because prior to this, I was not a participant in what the kids refer to as the socials. I&#39;m still not sure I like it. But so far, it&#39;s basically just a feed of dogs and sea otters and horror stuff. Like, if that&#39;s the internet, then OK, I&#39;m in.</p>
<p>Bryan! (03:12.525)
Mm. Ha ha.</p>
<p>Bryan! (03:25.053)
Yeah, yeah, you know, you get to curate it. Mine is all horror movie stuff and cats. Yep. So really is. Yeah, look upon me. Look upon my works and despair. You can also contact us directly at bring me the ax pod at gmail.com with any questions, comments or suggestions. Do let us know if there&#39;s a movie that you love and would like to hear us give it the business.</p>
<p>Dave! (03:33.322)
And that everybody is life in your 40s.</p>
<p>Bryan! (03:53.725)
Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts and you&#39;ll be doing us a favor by leaving us a five star review on Apple podcasts. So get to it. It&#39;s fun. I assure you. Also, apparently you can do this on Spotify also, if you listen to us on Spotify. So I just want to get that all out of the way right at the top of the show. So now&#39;s the time where we give you a little taste of what we&#39;re gonna we&#39;re gonna show you and I don&#39;t think you&#39;re ready for this.</p>
<p>Dave! (04:03.554)
Mm-hmm.</p>
<p>Dave! (04:07.318)
Yes.</p>
<p>Bryan! (04:22.557)
Let&#39;s give them a taste.</p>
<p>Dave! (06:04.902)
It is a comedy, comedy adventure.</p>
<p>Bryan! (06:08.019)
Yep. Whimsical in the extreme is how I&#39;ve been how I&#39;ve been describing it. There are movies even the worst movies, the weirdest movies tend to have a sort of internal logic about them. Not this one.</p>
<p>Dave! (06:24.483)
Mm-mm. No.</p>
<p>Bryan! (06:24.929)
No. And we&#39;re kind of actually, I see sort of stretching the definition of like what constitutes a horror movie with this one, because this one is firmly a family movie. It&#39;s just loaded with fucked up weirdness. That&#39;s just so uncanny and strange that it just it could only terrorize an entire generation.</p>
<p>Dave! (06:48.33)
I mean, it feels like a movie that was written by like seven different people at varying stages, like someone was like, here, I wrote a treatment for a movie and someone else took it was like, yeah, I&#39;m just going to add a couple of details and then pass it off to someone else I&#39;ve never met or talked to, because it goes through these waves of story, like narrative arcs that kind of reach a peak and then disappear and become something totally different in the weirdest fucking way.</p>
<p>Bryan! (07:00.453)
Yeah.</p>
<p>Bryan! (07:08.686)
Yeah.</p>
<p>Bryan! (07:14.33)
that is accurate as hell because it definitely feels like several movies all kind of jammed together and we&#39;ll get to it. There&#39;s a reason for that. It doesn&#39;t...</p>
<p>Dave! (07:23.298)
But it has, I think it has a horror, the reason I think it appeals to people with an interest in horror is that it really does in a weird way speak to the fears of children. And I was thinking about this all week, about like, what would I, cause I did see this as a kid. It was on HBO a lot, probably in the late eighties. And I don&#39;t remember what I thought of it when I saw it.</p>
<p>Bryan! (07:37.366)
Yeah.</p>
<p>Dave! (07:52.694)
But I keep thinking like, what would I have thought about this as a kid? Because as an adult, and I think most adults look at this and they&#39;re like, what the fuck is this movie? It is so goddamn weird.</p>
<p>Bryan! (08:01.741)
Yeah, there was a time, because there&#39;s, there&#39;s a few movies that I have memories of that were definitely age appropriate for when we were watching them as children. But they were also scary, like legitimately in a way that was like it was deliberate, but still like kid appropriate. It&#39;s like that&#39;s a thing that they don&#39;t really do anymore. Like whenever a movie is made for the family crowd or for children, it&#39;s</p>
<p>It&#39;s really, really like kiddy shit. Like, you know, you don&#39;t.</p>
<p>Dave! (08:34.734)
Or it has to appeal to both. It&#39;s like, we made this fucking animated movie, but we&#39;re sticking a bunch of adult jokes in there for mom and dad, to which I say, fuck you. Just make movies for kids and make movies for adults. They don&#39;t have to appeal to both people. If a parent can&#39;t sit through a two hour movie, fuck that, a 70 minute movie without chuckling at some sex joke, don&#39;t have kids.</p>
<p>Bryan! (08:40.949)
Yeah.</p>
<p>Bryan! (08:51.947)
Yeah.</p>
<p>Bryan! (08:57.965)
Yeah, so here&#39;s let&#39;s do some facts here. This this year was 1985 and some other movies that were released that year were reanimator and return of the living dead a real one two punch. Children&#39;s films through and through.</p>
<p>Dave! (09:12.502)
Both children&#39;s films. Hilarious romps for the family.</p>
<p>Bryan! (09:18.541)
for all to enjoy. Yeah, yeah, this is this is a fucking killer year because we also got Friday the 13th the new beginning, which is a movie that I know that you enjoy this one a great deal. I have come around to it in recent years. I used to not really care for this one. And I kind of lumped it toward the bottom of my rankings. But I kind of appreciate what they were doing with that sort of stuck with the</p>
<p>the unenviable task of having to make another sequel in a series that just ended with the final chapter. So, you know.</p>
<p>Dave! (09:54.59)
And so instead they were like, well, what if we just were absurd about it? Because Miguel Nunez is he&#39;s in that one, right? Isn&#39;t he a demon? He is awesome. And that is the one where I believe he dies in the outhouse. There&#39;s a lot to love.</p>
<p>Bryan! (09:59.6)
Yep.</p>
<p>Yeah.</p>
<p>Bryan! (10:08.981)
Yeah, yeah. Yeah, there is my personally my favorite is Reggie. Yeah, yeah. So let&#39;s see a couple more. The stuff also came out this year. It&#39;s the movie that made me very desperately wish that I knew what the stuff tasted like.</p>
<p>Dave! (10:14.207)
Oh, he&#39;s the kid, right?</p>
<p>Dave! (10:28.354)
I believe that is Larry Cohen&#39;s satirical take on big tobacco.</p>
<p>Bryan! (10:34.429)
I believe so or just or just consumerism in general. Yeah, yeah. And then lastly, Transylvania 6, 5000, which not a smart film, but I love it. I think it&#39;s very funny. I watched it not that long ago and it still makes me laugh a lot.</p>
<p>Dave! (10:38.294)
Very smart.</p>
<p>Dave! (10:43.1)
Not a smart film.</p>
<p>Dave! (10:51.01)
featuring notable racist Michael Richards.</p>
<p>Bryan! (10:57.undefined)
funny prop comedy. But yeah, so cast and crew, this is directed by a man named Michael Rubo, not rubbo. He&#39;s an Australian director who&#39;d been working in mostly shorts before this production. And then he went on to direct several more of the Tales for All series, which we&#39;re going to kind of address in a moment. But</p>
<p>Dave! (10:57.622)
Yeah, I&#39;ll give him that.</p>
<p>Bryan! (11:19.413)
of all the movies in this, in that long running series, for the he&#39;s directed the most and for the most part of them, they make up the bulk of his entire career like</p>
<p>Dave! (11:32.222)
He also made the Tommy Tricker movies, which I think I have actually seen one of.</p>
<p>Bryan! (11:34.569)
Yeah, yeah. They do sound very familiar. Like, and I&#39;m sure that we probably saw them on like Nickelodeon or something like that.</p>
<p>Dave! (11:44.362)
I think there were another an HBO thing, because I feel like HBO probably bought the license to all because in the trailer, it says it&#39;s a New World movie, but New World must have distributed it in the US because I don&#39;t think it was in Canada.</p>
<p>Bryan! (11:51.373)
Mostly.</p>
<p>Yeah. No, this was another one of those movies that like they didn&#39;t really have an eye on box office. It was mostly like we&#39;re going to sell the rights to this movie in to in as many venues as we possibly can and that&#39;s where they made the money. So, like, yeah, like New World just distributed it.</p>
<p>I&#39;m sure it was it was distributed by somebody else in Canada. I know that it was also sold overseas, so it was probably one of those things where.</p>
<p>Dave! (12:23.938)
So because on the Severin Blu-ray, there&#39;s an interview with Rock Demers, which I had a hard time believing that was that man&#39;s actual name, but it is. And he said that he didn&#39;t even know that this movie had an audience in the US until he came here for a screening, I think in California in like 2015 or something. And he was like, I knew that it was shown on HBO a little bit back in the eighties and maybe early nineties. He was like, but I didn&#39;t think anybody</p>
<p>Bryan! (12:44.887)
Yeah.</p>
<p>Dave! (12:53.822)
outside of Canada had really seen this and it has a huge fucking audience here. And he was like genuinely surprised by that. Which I think is a testament to how goddamn weird this movie is that people are like, oh shit I remember that I&#39;ll go see that in the theater. I mean the night I suggested this I checked, I was like looking at the listings at Somerville theater and they were doing like a midnight screening of it that night.</p>
<p>Bryan! (13:06.303)
Yeah, it&#39;s</p>
<p>Bryan! (13:15.265)
That&#39;s right. That was the timing of that was really strange. How about some cast? We got Well, you know, Michael Hogan, who is the journeyman actor. He does it all features TV voice probably best known as salt. I the sort of grouchy XO and Cylon in sci-fi is awesome Battlestar Galactica revival from a little ways back, which</p>
<p>Dave! (13:22.166)
Bunch of people you&#39;ve never heard of. Well, a few of them.</p>
<p>Bryan! (13:41.613)
That&#39;s a series that I love the hell out of. It&#39;s so fucking good, front to back. Some people may disagree with me on the ending. I love the ending. Fuck y&#39;all. You should, it&#39;s good. It&#39;ll surprise you.</p>
<p>Dave! (13:49.71)
I&#39;ve never seen a single episode.</p>
<p>Wasn&#39;t one of them a part of the NXIVM?</p>
<p>Bryan! (13:57.665)
Oh, I don&#39;t know. I... That would... I would be so disappointed, but yeah, I find myself...</p>
<p>Dave! (13:58.73)
I think one of them was. One of the women.</p>
<p>Dave! (14:04.982)
So, cause I&#39;m going to take a stand. I&#39;m a firm stand right now. We do not support cults of any kind. Not sex cults, not any kind of cult.</p>
<p>Bryan! (14:11.182)
No.</p>
<p>Bryan! (14:14.477)
Nope, we support cult films.</p>
<p>Yeah. So, oh yeah, but also he&#39;s everywhere in voice acting. I think the night the night that we kind of bailed on trying to do this the first time I went and put on the movie clear cut just as yeah, just on a. I don&#39;t I maybe I don&#39;t I don&#39;t know much about it, but yeah, I put it on a lark just because I&#39;ve been meaning to watch it for a while. Who should show up?</p>
<p>Dave! (14:33.058)
That&#39;s a Graham Greene movie. You know, that&#39;s a true story. I think it&#39;s a true story.</p>
<p>Bryan! (14:48.149)
Michael Hogan and he is as Canadian in that one as he is in this movie. It&#39;s it&#39;s so fucking great. That&#39;s right. We like you. So also this movie stars Matthew McKay who plays our lead Michael. This was his first starring role. He seems to be still fairly active in Canadian television productions. Following this played young Henry Ford in a biopic that probably leaves out all the Nazi and Union busting shit.</p>
<p>Dave! (14:52.354)
God, I love Canada. You hear me, Canada? I&#39;m talking at you.</p>
<p>Bryan! (15:17.833)
And then lastly, a kid named Seeluck Saysanase. I think I said that right.</p>
<p>Dave! (15:24.022)
Again, I think it&#39;s Sasa Masi. I don&#39;t know, I&#39;m pretty sure, but I don&#39;t know.</p>
<p>Bryan! (15:26.961)
Sasonassi. Yeah. He&#39;s Michael&#39;s best friend, Connie in this movie. This was his first role period. And after this, he&#39;d go on to play Yick on Degrassi Jr. High, and then basically all of the sort of Degrassi stuff that kind of flowers out from there.</p>
<p>Dave! (15:44.398)
I love Degrassi.</p>
<p>Bryan! (15:46.053)
I yeah, I&#39;ve seen a few episodes of it, but like nothing ever kind of chronological or in order. I think I told you this last time that we tried to do this, that I have, I have one really strong memory of this, and that was when I was in middle school and they were trying, they would occasionally kind of pull out the old VCR TV thing and they were doing, this is all pre-Dare and they were doing a program like</p>
<p>drug awareness education type stuff and to sort of dictate the dangers of drugs. They played an episode where they&#39;re all the grassy kids are going to some concert. And a couple of them, one of them being Shane takes LSD. And then like, I think the implication is he had some kind of bad trip and vanishes. And they never ever aired the second episode for us. So for the longest time, I was like, what the fuck happened to Shane?</p>
<p>It still bothers me. I suppose I could look it up and find out what happened to him.</p>
<p>Dave! (16:47.858)
It&#39;s some real hard hitting shit on that show. It&#39;s like one after school special after another.</p>
<p>Bryan! (16:51.885)
That&#39;s the thing is every episode that I have seen seems to be teens in trouble. Like like.</p>
<p>Dave! (16:57.474)
But I&#39;ll tell you, it comes out of something kind of interesting. It was like a kids acting workshop or something like that in Canada. I can&#39;t remember wherever they filmed the thing. And it&#39;s like this group of core, like core group of kids, they&#39;re not really actors. They&#39;re just kids who are taking, you know, participating in this kind of acting. It&#39;s like a, like a, not an after school program, but it&#39;s kind of like a program. And it&#39;s just that, like they just took them and put them in this show. So they&#39;re not really actors.</p>
<p>Bryan! (17:03.757)
Oh.</p>
<p>Dave! (17:25.346)
They&#39;re just actual kids. I mean, I guess all child actors are actual kids.</p>
<p>Bryan! (17:27.917)
I mean those kids are acting they would act the shit out of that stuff so</p>
<p>Dave! (17:31.734)
But like, yeah, they were just like, why don&#39;t we do a TV show? So then they do a TV show. And it&#39;s like, oh, it&#39;s kind of cool. But I also just love Canada and I love Canadian television. So that&#39;s why I own the Blu-ray. Actually, there&#39;s a Blu-ray. I actually own it on regular DVD.</p>
<p>Bryan! (17:41.133)
Yeah.</p>
<p>Bryan! (17:47.613)
Oh, no shit. Yeah. So nowadays, Cilic is very active in film production. And he&#39;s a like a professionally he&#39;s a first AD. So that&#39;s assistant director. And he&#39;s done gigs on movies that you have definitely seen. Like he works on very high profile stuff. So some Oh, yeah, we&#39;re gonna get there because I&#39;ve got notes before we even address</p>
<p>Dave! (18:07.727)
You should talk about the music too. Just talk about who provides the music and why it is notable.</p>
<p>Bryan! (18:16.961)
before we even address the movie. That&#39;s right.</p>
<p>Dave! (18:17.387)
Celine Dion of it all, which is actually the name of my biography.</p>
<p>Bryan! (18:22.601)
the Celine Dion of it all, the Dave White story. This is known as Operation Bear de Pinot in Quebec. And it is part two of the Tales for All series. I mentioned Tales for All earlier. This is the product of a Canadian producer that Dave mentioned named Rock de Mer. And the project was conceived as a series of family films that would also be adapted into books. So</p>
<p>where he remembers seeing this movie. I had, when he mentioned it, I had no memory of this movie as a movie, but I did remember it being a book in my school library. So.</p>
<p>Dave! (19:02.186)
And I think I told you the origin story, because again, I was watching that interview with Rock DeMille.</p>
<p>Bryan! (19:08.353)
Yeah, it&#39;s a great. That&#39;s a really, really nice interview.</p>
<p>Dave! (19:11.082)
Yeah, he seems like a really great guy. He&#39;s like, he&#39;s this dude who was sort of like really, really into film. But at the time, I think it was like the fifties, maybe late fifties, early sixties. And he decides to like travel around the world and he gets really into like Croatian. I think it&#39;s either Czech or Croatian cinema. I think it&#39;s Czech and like Russian cinema. And he just like becomes really inspired by world cinema. I think you can kind of see that influence.</p>
<p>Bryan! (19:13.55)
Yeah.</p>
<p>Bryan! (19:32.165)
Check.</p>
<p>Dave! (19:40.77)
like world cinema in this movie. But he really wants to like make movies that kids can watch. And so he like starts programming at theaters around Montreal, I think. And eventually he reads an article in the newspaper about like rising, like dramatic increase in teen suicide. And he is so moved. He&#39;s still moved in the interview. He read this thing in like the early seventies or something.</p>
<p>And he still like kind of tears up a little bit. And I was just like, damn man, you seem like a good person. So he decided he wants to make a movie or want to make stories for younger people that shows them that life is difficult, but it is worthwhile. Now that is a cheesy line, but it&#39;s such a noble pursuit that it&#39;s hard to make fun of someone for that.</p>
<p>Bryan! (20:25.476)
Yeah.</p>
<p>Bryan! (20:31.225)
When, well, the thing is, when it&#39;s delivered in earnest, if you were to make fun of it, you&#39;d be a fucking asshole, because this is a person who&#39;s speaking from the heart, and you believe them. And I definitely pick that up.</p>
<p>Dave! (20:48.05)
I mean, we can argue over whether or not he succeeds in that, because I&#39;m not so sure about that. But I think it is a genuine expression. I think it is thoroughly respectable.</p>
<p>Bryan! (20:51.84)
Hahaha</p>
<p>Bryan! (21:00.609)
Yeah. So as of this recording, there are 25 tales from all tales for all movies with the latest in the series being in production as recently as this year. The unofficially began with a real shitty looking children&#39;s movie called The Christmas Martian, which is produced by demer in 1971. And if you get that severed release of this, which I recommend that that&#39;s a that&#39;s a really, it&#39;s a really good disk like all of the</p>
<p>Dave! (21:27.544)
Just give your money to Severin.</p>
<p>Bryan! (21:28.877)
Yeah, like all like it&#39;s a it&#39;s a very nice looking transfer. And also the extras are all like pretty interesting and fascinating. But there&#39;s footage of this movie. There&#39;s actually footage of lots of the Tales for All movies on the extras. But this one in particularly, you see a lot of it. And it looks. Utterly terrifying in places because like the.</p>
<p>Dave! (21:49.346)
You know what it reminds me of? It reminds me of that commercial. Remember that I&#39;m from Mars and I like to eat candy bars commercial? It reminds me of that, where it&#39;s just like, this is the lowest budget looking janky shit I&#39;ve ever seen and I wanna see the whole fucking movie now.</p>
<p>Bryan! (21:53.836)
Yes!</p>
<p>Bryan! (22:04.195)
Yeah, the Christmas Martian himself has this like weird like net thing over his face that makes him look so uncanny and weird. But as we&#39;re gonna find out uncanny and weird is kind of the brand for this. So this the series officially kicks off in 1984 with a movie called The Dog Who Stopped the War.</p>
<p>Dave! (22:22.874)
I am anti-dog who stopped the war. I thought that name sounded great, and then I learned what it was about, and I was like, fuck you.</p>
<p>Bryan! (22:28.069)
That&#39;s the thing. If it&#39;s a children&#39;s movie and there&#39;s a dog involved, 99% chance that dog&#39;s gonna die. And it does. The dog stops the war by dying when a snow fort collapses on it. So this is a movie I... So I didn&#39;t remember seeing this movie. You did. I remember very clearly watching The Dog Who Stopped the War.</p>
<p>Dave! (22:38.167)
in a collapsing snow fort.</p>
<p>Dave! (22:49.814)
Like what a delightful title. Yeah, except you know what it needs? An ellipsis and then because he dies in a collapsing snow fort. Is it a long title? Yes, but at least it tells the story.</p>
<p>Bryan! (22:52.142)
Yeah.</p>
<p>Bryan! (22:57.089)
I know, I really, if, since we&#39;re talking about a dog in a kids movie, I really do wanna get it up front, like, just tell me how the dog stops the war so I know whether or not I&#39;m gonna watch this movie. Because I still get upset when the dog fucking dies in a movie.</p>
<p>Dave! (23:12.214)
Oh, I won&#39;t watch anything that has that. I heavily rely on doesthedogdie.com and I recommend it to everyone. You should pay for the app if you need to. I get it.</p>
<p>Bryan! (23:20.421)
the Lord&#39;s work. They&#39;re doing they&#39;re doing the Lord&#39;s work. Yep. So, uh yeah. So, as we said, this played very heavily on HBO uh back in the day and for most people who remember this movie, that&#39;s basically where they saw it. According to Demare, they intended for the movie to be full of weird and unsettling imagery but I can&#39;t. Yeah, I kinda call bullshit on that like this. That seems a little</p>
<p>Dave! (23:42.442)
Success!</p>
<p>Dave! (23:46.582)
Yeah, that&#39;s a little retconny to me.</p>
<p>Bryan! (23:50.661)
Because none of the future Tales for All movies that are available for seeing on that severed disc, they do not look like this movie. It is dark. It&#39;s so fucking weird. He also insists that the underlying theme of this movie is death and loss, which we&#39;re going to sort of address the kind of weird...</p>
<p>Dave! (24:14.954)
Oh, I&#39;m gonna address it throughout.</p>
<p>Bryan! (24:21.357)
because it does not make any god damn sense whatsoever.</p>
<p>Bryan! (24:28.629)
In the early stages of the movie, it&#39;s like heavily implied that she&#39;s dead, but then not</p>
<p>Dave! (24:33.538)
but it is explicitly stated that she&#39;s not dead.</p>
<p>Bryan! (24:36.681)
Yeah, but everybody kinda acts all morose. You know what?</p>
<p>Dave! (24:39.666)
It&#39;s the only explanation for why anything works in this movie, because otherwise, if you take that out, it&#39;s just like, then why is everyone such a goddamn lunatic? Also, I think English is the first language for about four people in this movie. But God bless them, they&#39;re doing their best and, you know, they speak better English than I do French, so.</p>
<p>Bryan! (24:49.294)
Yeah.</p>
<p>Bryan! (24:53.413)
Yeah, yeah. They&#39;re they&#39;re making it work. They&#39;re making it work. Yep. So, if we&#39;re talking through this movie and you think to yourself, self, what the <em>**</em> are these guys talking about? Don&#39;t worry about it. Uh Michael Rubo made this story up on the fly as a bedtime story for his kids before just taking all of the pieces and combining them into a script and turning it into a movie which is why it has that sort of angular quality to it where one</p>
<p>entire plot thread just resolves and then an entirely new one pops up in the same movie that you&#39;re watching. This will happen about three times. This also, this going back to what Dave was mentioning earlier, this movie features the first English language songs by Celine Dion, who was 16 at the time of their recording. The main theme</p>
<p>is a song called Listen to the Magic Man and it is insane. I have played it back several times on YouTube. There&#39;s the video you can find the YouTube on uh you could find the video on YouTube. I recommend it because it&#39;s really something. It has</p>
<p>Dave! (26:02.67)
You know what this movie is? This movie is like if someone took Mr. Balloonhands and made a movie out of it.</p>
<p>Bryan! (26:09.953)
Not much not much air no way</p>
<p>Dave! (26:10.058)
It&#39;s just, yeah, not my chair, not my problem. It&#39;s just like, here&#39;s a bunch of weird shit that someone is saying as they&#39;re rambling, and we&#39;re just gonna make a fucking movie out of it. And then we&#39;re gonna set it to Celine Dion&#39;s first English language track.</p>
<p>Bryan! (26:18.977)
Write down.</p>
<p>Bryan! (26:22.753)
Yeah, right down to the lyrics of the song, because basically the melody and the orchestration, it all kind of sounds like somebody was very clearly kind of aping the never ending story. But the lyrics are like a Tori Amis song. It was originally recorded in French as Don Lamond du Magician, which means in the hand of the... I&#39;m so good at it. I&#39;m so fucking good at French.</p>
<p>Dave! (26:25.256)
Mm-hmm.</p>
<p>Dave! (26:45.302)
Your French is exquisite.</p>
<p>Bryan! (26:50.737)
It means in the hand of the magician or in the magician&#39;s hand. And then the English version is kind of awkwardly retitled to match the melody and the meter. And the other Celine Dion song plays over the end credits. It&#39;s called Michael&#39;s song. But yeah, she was like a teenager when she was doing this and oh, the places she will go.</p>
<p>Dave! (27:08.79)
as she was being groomed by that old man she married. Yeah, hey everybody, hey everybody, remember that? Remember when Celine Dion married a man who was like 40 years older than her?</p>
<p>Bryan! (27:12.92)
Ha ha ha!</p>
<p>Bryan! (27:19.269)
Good God. Yeah, man. I don&#39;t care for it. But I&#39;m glad I&#39;m glad she seems to have come out of it.</p>
<p>Dave! (27:26.73)
I don&#39;t know, I think she&#39;s doing okay.</p>
<p>Bryan! (27:28.237)
Yeah. So the movie opens on breakfast as Suzy is the is Michael&#39;s sister. She&#39;s rattling around the kitchen in an oversized robe that we&#39;re going to find out belongs to her mother. She&#39;s putting all sorts of shit in a blender.</p>
<p>Dave! (27:44.098)
And they throw us right into what sounds like a voiceover. The ADR use in this movie is very heavy.</p>
<p>Bryan! (27:52.085)
It&#39;s inconsistent too, because they use it a lot when they need to dump a lot of shit on you in a hurry, and then it never comes back.</p>
<p>Dave! (28:00.598)
And it always feels to me, because this is my first note about the mom thing. It always feels to me like they&#39;re shoehorning shit in after the fact. So she&#39;s putting shit into a blender and whatever she&#39;s making looks disgusting. She&#39;s gonna be a terrible mother if this is what she thinks parenting is. But he says, someone says something about like, their mom has gone away.</p>
<p>Bryan! (28:22.809)
she has gone away but he says it in such a way as to suggest mom is not ever coming back.</p>
<p>Dave! (28:30.558)
Right, like this is how you explain to a child that someone is dead. They&#39;ve gone away. But then she&#39;s got this giant frumpy bathrobe on. It is what Nina Garcia on Project Runway would call mumsy. Oh, it&#39;s a little madam. And so she says, you know, why are you wearing that or something like that? She says, I told you, she said I could. And so it&#39;s like they&#39;re really.</p>
<p>Bryan! (28:33.56)
Yeah.</p>
<p>Bryan! (28:55.234)
Yeah.</p>
<p>Dave! (28:57.234)
And then she says, she&#39;s not dead. She says, it goes on and it&#39;s like, it seems like you&#39;re going out of your way to remind us or assure us all that the mother is absolute. Don&#39;t worry kids, the mother is not dead.</p>
<p>Bryan! (28:58.945)
Yeah. Well, cuz they let they</p>
<p>Bryan! (29:09.893)
Yeah, cuz they actually, they they let that hang for about ten minutes because Michael goes to school and then her dad is like what the <strong><strong> are you doing? You need to go to school and uh uh she&#39;s like, I don&#39;t know why he&#39;s so upset. It&#39;s not like she&#39;s dead or something like that. It&#39;s like what what then what was the </strong></strong> deal with the setup?</p>
<p>Dave! (29:32.01)
And so here&#39;s what, when we were watching it, Michael suggested to me, Michael, not the movie, suggested to me, what if it was, like they changed the script, because obviously they didn&#39;t go back and reshoot all this shit, that would have taken forever. So what if they changed the script a little bit, but they didn&#39;t change the whole thing for consistency, they just changed certain scenes in order to reinforce it. So when it comes up later, it really reads like she is dead because they never bothered for the sake of</p>
<p>Bryan! (29:51.746)
Yeah.</p>
<p>Dave! (30:01.974)
continuity to actually revise everything.</p>
<p>Bryan! (30:05.013)
It&#39;s almost like the original intention was this was a single father dealing, you know, and children dealing with grief kind of thing. But then at some point, yeah, because then at some point, somebody was like, ah, that&#39;s kind of a bummer. Here&#39;s some you know, here&#39;s some filler you can put in so that we can kind of salvage that.</p>
<p>Dave! (30:11.894)
Because that&#39;s the only way it makes sense.</p>
<p>Dave! (30:21.93)
Yeah, it feels because it really feels like they got production notes back and we&#39;re like, no, you can&#39;t let the mother be dead. This is a children&#39;s movie. But without the mother being dead, the rest of it doesn&#39;t make sense.</p>
<p>Bryan! (30:28.558)
Yeah.</p>
<p>Bryan! (30:33.549)
Yeah.</p>
<p>Dave! (30:34.59)
And so, cause then she&#39;s like, cause then she puts the thing down on the table and she&#39;s like, what? It&#39;s exactly what mom makes. And I thought, this is what your mom, your mom makes fruit and raw eggs in a blender? Like if she&#39;s not dead, she&#39;s a terrible mother. She may as well be dead. They&#39;re safer that way.</p>
<p>Bryan! (30:46.394)
I know.</p>
<p>Bryan! (30:52.161)
Yeah, and this whole daughter as mother thing is going to make me extremely uncomfortable because it never it never veers into like inappropriate territory. It just dances perilously close to the edge. So yeah, the two of them are basically fighting over not wanting to eat what she made for breakfast. I don&#39;t blame him. It looked fucking vile.</p>
<p>Dave! (30:56.008)
It&#39;s creepy as shit.</p>
<p>Bryan! (31:16.577)
And then she runs upstairs and tell her dad about it. And then this is when she does the thing where she&#39;s like, it&#39;s not like mom&#39;s dead. What we&#39;re gonna find out is mom&#39;s in Australia because her father, I think her father died and she has to like basically deal with his estate and sell the house. That&#39;s what she&#39;s.</p>
<p>Dave! (31:26.05)
for some reason.</p>
<p>Dave! (31:34.75)
Okay, you could have let grandpa be dead. There&#39;s someone needs to be dead in this movie. I mean, that still wouldn&#39;t explain why the dad is a fucking train wreck too. He looks disheveled. He just, he wears the same clothes through the entire movie. He is a terrible parent as well. These kids are in danger.</p>
<p>Bryan! (31:38.658)
Yeah, yeah.</p>
<p>Bryan! (31:47.589)
through the entire movie.</p>
<p>Dave! (31:52.498)
And not just the danger that ensues, but I think long-term traumatic experience.</p>
<p>Bryan! (31:52.527)
Yep.</p>
<p>Yeah. Because when we&#39;re introduced to dad, that&#39;s Michael Hogan. He is like disheveled and he&#39;s been up all night for some unspecified reason. Um and he seems to just sleep on a cot in the attic. So, now, we uh enter Connie and</p>
<p>Dave! (32:16.794)
We also have you&#39;ve neglected to mention that Michael is wearing what is very obviously a wig And it is it is a positively british looking wig like it is at austin powers like will the real jan Brady please stand up like it is an obvious wig</p>
<p>Bryan! (32:22.305)
Yes. Yeah.</p>
<p>Bryan! (32:26.249)
Yeah, it looks like. It looks like a beetle wig. Yeah. So now Connie comes in and this is Michael&#39;s best friend. He&#39;s great. He&#39;s let&#39;s himself in helps himself. Yeah. And what we learn through a little bit exposition here that Michael kicks ass at soccer and that the old spooky house near the nearby but on the way to school burned down the night before.</p>
<p>Dave! (32:37.782)
And this kid is great. He is awesome through the entire movie.</p>
<p>Bryan! (32:54.165)
That&#39;s where the winos live.</p>
<p>Dave! (32:56.618)
Yeah, air quotes for winos. And I love that even in my closed captions, it refers to them as winos, but like, but like not in dialogue. It&#39;s just like, you know, winos yelling. It&#39;s just like, I think it&#39;s great that even the closed caption calls them a wino.</p>
<p>Bryan! (33:09.219)
Yeah.</p>
<p>Bryan! (33:14.357)
Yeah. So, uh, we, we get a little bit of a, like a flashback where Michael sees the winos out on the street begging and he gives the, he gives one of them some money the day before.</p>
<p>Dave! (33:24.29)
But he does, he goes up and throws the money down, he&#39;s feeding a wild animal. Like he just like very tentatively approaches and kind of like chucks it on the ground and runs away. Like, yeah, he might give you wino disease.</p>
<p>Bryan! (33:29.261)
Ha ha!</p>
<p>Bryan! (33:33.689)
Yeah, don&#39;t get too close, he might bite you. Yep, it&#39;s all set to some really sad wino music too.</p>
<p>Dave! (33:41.226)
And Connie is super into the destruction of this house. He is so, he&#39;s excited about the fire. He&#39;s excited about people choking to death. And it&#39;s kind of like, all right, you need to reel it in, Connie. Little weirdo.</p>
<p>Bryan! (33:45.034)
Holy shit.</p>
<p>Bryan! (33:52.174)
Yeah. So yeah, on the way to school. Now, Michael checks to see if there&#39;s any mail from his mother who could just fucking call the house like a normal person. Because these three</p>
<p>Dave! (34:02.61)
Again, it&#39;s a little forceful about like, don&#39;t worry, mom&#39;s gonna write a letter, which she can do, cuz she&#39;s not dead.</p>
<p>Bryan! (34:06.485)
right. Yeah, yeah. I kind of wish that she would or would just pick up the phone because these three are not handling her absence well.</p>
<p>Dave! (34:16.65)
No. And she just went on vacation, basically. Like, I mean, if this woman cannot go away for a couple of days, if you all fall apart that quickly...</p>
<p>Bryan! (34:20.173)
Basically, she&#39;s just getting a nice...</p>
<p>without just like they descend into madness almost immediately.</p>
<p>Dave! (34:29.334)
Yeah, she left that morning and it&#39;s like fucking Lord of the Flies.</p>
<p>Bryan! (34:32.849)
Yeah. So, and now, and now, on the way to school, for some reason, Connie is doing his best not to step on any ants. And I don&#39;t believe</p>
<p>Dave! (34:41.054)
Doesn&#39;t mind a building full of winos burning to death, but don&#39;t step on the ant.</p>
<p>Bryan! (34:44.941)
don&#39;t leave them ants alone. Yeah. So, on the way by the old spooky house, we now learn that some people died there in the fire. So, just put a little pin in that. Meanwhile, back at home, Susie is still there like in the robe fretting over housework. It&#39;s also, it&#39;s not really clear who&#39;s the older of the two. I think I think she&#39;s supposed to be the older.</p>
<p>Dave! (35:06.578)
I think it&#39;s her. But she finds moldy plates of food in the father&#39;s art studio, and it&#39;s like, dude, what the fuck? You have children. Why do you have moldy plates of food just laying around?</p>
<p>Bryan! (35:16.439)
Yeah.</p>
<p>Bryan! (35:21.313)
Yeah. Um and so now she&#39;s she says she&#39;s yeah like she&#39;s spreading over housework when she should be at school and we learn that basically dad is an artist and he has an appointment to try and sell some of his work to a guy named the rabbit. I know. Yeah. What&#39;s the deal with the code names in this movie? Cuz that&#39;s not the last one we&#39;re gonna</p>
<p>Dave! (35:39.498)
Which I, hmm, we&#39;ll get to that later.</p>
<p>Dave! (35:45.758)
That is bizarre.</p>
<p>Bryan! (35:50.265)
We&#39;re in art class and we are introduced to the senior.</p>
<p>Dave! (35:54.378)
Yeah, the senior who&#39;s a little too handsy for my liking.</p>
<p>Bryan! (35:57.733)
I don&#39;t care for the senior at all. We&#39;re not supposed to like him but again, yeah, he&#39;s a real <strong><strong> </strong></strong> He&#39;s also an artist but he might be in the wrong line of business because he detests imagination and he is obsessed with his dog, Jim.</p>
<p>Dave! (36:00.674)
No, he&#39;s a sack of shit.</p>
<p>Dave! (36:15.43)
My note says the kids in this art cast are so goddamn French Canadian. Except for the one kid. There is one kid who&#39;s like, but Senor, it&#39;s scary.</p>
<p>Bryan! (36:21.906)
Oh, there is there is that one.</p>
<p>Bryan! (36:28.161)
Yeah. So, the class is currently tasked with drawing Jim while the senior, a flamboyant French guy who could be perfectly at home at like Pink Floyd&#39;s The Wall.</p>
<p>Dave! (36:41.014)
I mean, I will give it to the dude who plays this character. He really does it to the hilt.</p>
<p>Bryan! (36:44.777)
he commits in a way that you&#39;re not expecting. Yeah, he basically his whole thing in this scene is he&#39;s going from kid to kid who&#39;s like basically sketching a dog and he&#39;s just.</p>
<p>Dave! (36:54.306)
They&#39;re all just trying to draw Jim who&#39;s just sitting there on a chair, except for Michael, who&#39;s drawing the dog in like a Faustian hellscape. Yeah, what, you&#39;re just, what are you looking at?</p>
<p>Bryan! (37:02.303)
With flames!</p>
<p>Bryan! (37:06.321)
Yeah. And and yeah and the senior meanwhile is like ferociously critiquing the children like just at no imagination. Do not use imagination. Draw what you see. So, yeah, we get to we get to Michael&#39;s and it&#39;s Jim but he&#39;s yeah he&#39;s again he&#39;s like surrounded in hell.</p>
<p>And so the senior also grabs Connie&#39;s drawing and threatens to tear it up. But Connie counters the threat with his own threat. He tells him he&#39;ll never come back to his class if he does, which is not a thing that I knew you could do when I was a kid.</p>
<p>Dave! (37:34.679)
Yeah.</p>
<p>Dave! (37:41.814)
No, fucking my man Connie standing up to this goddamn tyrant.</p>
<p>Bryan! (37:44.485)
like, he&#39;s ballsy. Yeah. I mean, I wonder what would happen if I&#39;m one of my teachers was like, uh like this is <strong><strong> and I would have been like, if you touch that </strong></strong> thing, I will never come back to this class. They&#39;re gonna be like, uh I&#39;ll then I&#39;ll see you with your parents tonight.</p>
<p>Dave! (38:01.31)
And younger people might think, well, a teacher would never say that. To which I say, a teacher would absolutely fucking say that. Every teacher I ever had was a total piece of shit who did not have any right to be teaching children.</p>
<p>Bryan! (38:12.137)
Oh, yeah, my experience with teachers was similar, not it&#39;s a much different world these days, like the teachers that my children have way different, way more invested in their education, whereas ours seem to be burdened with our education.</p>
<p>Dave! (38:28.182)
Yeah, and I imagine like being a teacher is probably extremely difficult. And still, they were all terrible people. So you hear that Northampton Elementary School? You can eat a bag of dicks you all fucking sucked at your jobs. Yep, anyway, got that off my chest. Carry on with this film. It&#39;s a very triggering movie for me from the outfits to the teachers.</p>
<p>Bryan! (38:42.153)
Yeah. Fuuuck you! Yup. Hahaha.</p>
<p>Bryan! (38:53.251)
Yeah.</p>
<p>Bryan! (38:58.581)
So, back at home, Michael&#39;s father tries to sell a number of paintings of birds to the rabbit.</p>
<p>Dave! (39:05.59)
And he says, he says, my last show was a sellout. And I thought, that can&#39;t possibly be true. This art is terrible. It&#39;s objectively bad painting. And they&#39;re all like whimsical toucans fucking smoking a cigarette or something.</p>
<p>Bryan! (39:12.882)
It&#39;s pretty. Yeah.</p>
<p>Bryan! (39:21.093)
Yeah, I do. I do like the one that like the big like you cannot have this one. The one of the family paying playing tennis because the the the perspective is really <em>**</em> weird.</p>
<p>Dave! (39:31.106)
Yeah, it all feels like it&#39;s pulled from a fucking Kids in the Hall sketch.</p>
<p>Bryan! (39:35.337)
Yeah. So, um, this is this is where we find out that Michael&#39;s mother is off doing the thing in Australia. And meanwhile,</p>
<p>Dave! (39:45.654)
because the rabbit wants to buy the fucking tennis painting and he gets like weirdly possessive. He&#39;s like, no, this is a gift. And it&#39;s like, okay, if it&#39;s a gift, that&#39;s fine, but why are you being a fucking weirdo about it?</p>
<p>Bryan! (39:52.95)
It&#39;s a.</p>
<p>Bryan! (39:57.301)
Yeah, it&#39;s a gift for Suzy.</p>
<p>Dave! (40:01.23)
It&#39;s almost like your wife is dead or something.</p>
<p>Bryan! (40:01.381)
and I know. Yep. Gotta gotta protect this painting of the family altogether. Altogether. All of us. Yeah, all of us conspicuously alive cannot sell this one. So now, on the way back from school, Michael and Connie are back at the old spooky old house. They decide to climb up on the wreckage of it in order</p>
<p>Dave! (40:07.978)
And it&#39;s the last thing we have. We all, when we all played tennis.</p>
<p>Dave! (40:26.94)
Yeah, Connie&#39;s got an idea. Let&#39;s break into the burned out death house that is still a smoldering crime scene.</p>
<p>Bryan! (40:33.665)
Yeah. And so he eventually like falls off, gives up on it, but Michael keeps going.</p>
<p>Dave! (40:38.902)
But what are they climbing on? Like, so the house is like a kind of like a bombed out Victorian wreck, like why does it have a rickety old coal chute outside?</p>
<p>Bryan! (40:47.801)
That&#39;s the thing. It looks like it looks like a leftover set from like a scene where they shot some shit that was like set during the blitz in London or something.</p>
<p>Dave! (40:55.178)
Yeah, this is where the dog was supposed to stop the war.</p>
<p>Bryan! (40:58.209)
Yeah. So yeah, Michael climbs up and gets inside and he walks around. And then we cut back out to outside as Connie is like, Hey, Michael, come, come back, come down. And then we hear Michael screaming from the inside and it cuts to a fucking intense extreme close up of him screaming with his hair standing on end and then his body like lifelessly slides down the coal shoot out the window just unconscious.</p>
<p>Dave! (41:21.802)
Yeah, so like they scared these ghosts, scare the shit out of him. And then they throw him out the window down the coal chute. It&#39;s like he gave you money. Yeah, he was a weirdo about it. But still, this is how you repay him.</p>
<p>Bryan! (41:30.405)
I&#39;m going to go to the bathroom. Yeah. Well, well, we&#39;re going to find out one of the winos is like an okay dude. The lady wino fucking sucks. So, I&#39;m going to assume</p>
<p>Dave! (41:40.99)
Yeah, she&#39;s got surreal, literally kind of wild personality.</p>
<p>Bryan! (41:44.385)
I&#39;m gonna assume that it was her who scared the shit out of him and threw him out the window. So...</p>
<p>Dave! (41:49.386)
Yeah, it is definitely a problematic portrayal of mental illness and homelessness. Just FYI.</p>
<p>Bryan! (41:54.862)
Yeah. So, back at home, Suzy is still not at school. She&#39;s managing. She&#39;s managing the finances for some reason.</p>
<p>Dave! (42:00.01)
But she does appear to be doing the family&#39;s accounting?</p>
<p>Dave! (42:05.738)
Yeah, she&#39;s sitting there with an adding machine and like a pen and a paper. Like, what&#39;s going on here? Why is she making gross breakfast and now doing the bookkeeping?</p>
<p>Bryan! (42:11.713)
Yep.</p>
<p>Bryan! (42:15.125)
And she&#39;s like, but she&#39;s also like fretting over it.</p>
<p>Dave! (42:18.006)
Well, you know, things are harder now that mom is definitely not dead.</p>
<p>Bryan! (42:21.305)
Yeah. And Michael is now brought into the house unconscious. Connie goes over what happens and we get a little</p>
<p>Dave! (42:29.942)
But listen, I gotta tell you, this is the first moment in this movie where, because throughout the entire thing, everyone&#39;s real fucking casual about what is happening and what happens to Michael. He brings him home in a shopping cart. He is clearly in some kind of coma, and they&#39;re all like, Dad, don&#39;t worry about it. Just put him on the couch. He&#39;ll be fine. Walk it off, buddy.</p>
<p>Bryan! (42:40.333)
The- oh my god.</p>
<p>Bryan! (42:47.269)
Oh, there&#39;s yeah a little later on also there&#39;s going to be like a weird like disparity between how Michael&#39;s affliction is being handled sort of internally how he&#39;s how he deals with it and how his father does in a way that really bothered me because like he&#39;s obviously like has this outsize</p>
<p>like expression of rage and sadness. And when the hair starts growing, his dad&#39;s like, all right there, Michael boy, add a boy. Like that&#39;s a way to grow that hair. Yeah. Yep. So, so now, but as this is happening, like Michael&#39;s brought into the house, Connie goes over what happens when we get a little POV in the spooky house is he&#39;s, you know,</p>
<p>Dave! (43:16.918)
Yeah, this is a sign of a problem. Like, this is a health crisis if nothing else.</p>
<p>Dave! (43:30.11)
And I&#39;ll tell you, this can&#39;t be right. This he was in the house for all of like five fucking seconds before he gets chucked out the window. But in the flashback, he&#39;s in there for like two hours wandering around.</p>
<p>Bryan! (43:36.941)
And we get, yeah, we, we get like, yeah, we got like 20 minutes of like sneaking around the ruins kind of thing. It&#39;s crazy.</p>
<p>Dave! (43:44.074)
But then they show the house from a different perspective and it looks like a totally different house. Like it doesn&#39;t have the weird coal chute. Like the other one was like a bombed out Victorian. And this is like just some old house.</p>
<p>Bryan! (43:48.653)
Yeah.</p>
<p>Bryan! (43:56.481)
Yeah. The the implication here is that Michael saw the burnt up bodies or he saw ghosts of the burnt up bodies or something.</p>
<p>Dave! (44:05.686)
Yeah, well I think one or two of them is an unreliable narrator, is what I&#39;m getting at. And they&#39;re really not very nice to Connie either. They&#39;re basically like, Thanks kid, can you get the fuck out now?</p>
<p>Bryan! (44:09.377)
Yep.</p>
<p>Bryan! (44:15.189)
I know, yeah, his dad is, he&#39;s not like rude, he&#39;s just mostly like, what are you doing in my house again?</p>
<p>Dave! (44:21.886)
And Connie&#39;s the coolest kid in this movie. He&#39;s the coolest kid in the fucking, in all of Montreal. Like, why are you telling him? Tell Susie to get out. She&#39;s a weirdo.</p>
<p>Bryan! (44:24.429)
He is.</p>
<p>Bryan! (44:28.559)
Yeah.</p>
<p>Bryan! (44:31.825)
certified, keba cool guy. Yeah. So, later that night,</p>
<p>Dave! (44:35.318)
Yeah!</p>
<p>He&#39;s fucking dressed like Madonna from Who&#39;s That Girl. Like, he is real cool.</p>
<p>Bryan! (44:40.517)
that. That&#39;s right. Yeah. So, Suzy finds the cat sleeping on Michael&#39;s head and so she shoes it away and then the next morning, Connie comes over for breakfast and Michael comes in and we learn that he lost all his hair overnight and</p>
<p>Dave! (44:55.858)
Now the amazing thing about this though is that they clearly just shaved this kid&#39;s head. Why didn&#39;t they put a bald cap on him? Why did they actually shave his head?</p>
<p>Bryan! (45:00.503)
Oh yeah.</p>
<p>Bryan! (45:05.205)
Yeah, yeah, I don&#39;t know. I have no answer for that.</p>
<p>Dave! (45:09.462)
And this scene is also, cause this is in the trailer and I think I&#39;ve used it for something else online. But it&#39;s like, you can tell is this questionable directing because the father and Susie look like genuinely surprised or shocked, but you can tell that Connie doesn&#39;t really know what to be doing because he kind of starts laughing like he&#39;s uncomfortable. And then he&#39;s like, oh wait, no, I&#39;m supposed to be surprised. But they just fucking roll with it anyway. They&#39;re like, no time, we gotta keep going.</p>
<p>Bryan! (45:29.399)
Yeah.</p>
<p>Bryan! (45:36.289)
Yep. They got what they want out of the foreground. Connie can, you know, they can deal with that later.</p>
<p>Dave! (45:41.966)
I mean, he looks like he has leukemia. I mean, he even acts like he has leukemia. Like he comes fucking stumbling down. Like he was just hit by a car and it knocked all of his hair out or something. Because he&#39;s like, his eyes are closed. He&#39;s kind of bumbling around the house. And it&#39;s like, why does it seem like he&#39;s been drunk?</p>
<p>Bryan! (45:57.861)
Yeah, he&#39;s mumbling and <em>**</em> You know, and naturally, they blame the cat for sleeping on his head. Like, this is the thing that&#39;ll happen.</p>
<p>Dave! (46:04.866)
Yeah, like, this is a fucking 14th century folktale. The cat stole his hair. That is not what happens. There is a medical crisis happening in this house, and nobody seems to care.</p>
<p>Bryan! (46:22.734)
I know. I know. I know. I know. I know. I know. I know. I know.</p>
<p>Dave! (46:24.798)
Yeah, you might think it&#39;s like his mother died or something. Because the way... Nope!</p>
<p>Dave! (46:33.302)
Yeah, mine says this is a mom&#39;s dead breakdown, not an I lost my hair breakdown because he fucking loses his shit so hard. Like, you do not see people cry like this unless they witness something terrible and they&#39;re doing it in the moment.</p>
<p>Bryan! (46:41.825)
His o-</p>
<p>Bryan! (46:49.089)
Yeah. His his ongoing reaction to his hair loss is fucked up and very weird because at no point are we to think that his hair will not grow back. As a matter of fact, his dad consoles him by saying here like touch, you know, like I just shaved what happens if I don&#39;t shave for a couple of days. He&#39;s like it&#39;s bumpy and he&#39;s like, right. Your hair is going to grow back. Michael spends from what we can tell.</p>
<p>the better part of like two weeks like uh like the scene like the that episode of the simpsons where Bart breaks his leg and he can&#39;t go in the pool he&#39;s it&#39;s like that uh but also wildly tis so he&#39;s gonna he&#39;s gonna hide in his bedroom and break down into hysterical sobbing tears like anytime</p>
<p>Dave! (47:30.026)
Did St. Swithin&#39;s Day already?</p>
<p>Dave! (47:42.242)
But also he clearly didn&#39;t lose his hair. This is kind of where I&#39;m going. He didn&#39;t lose his hair. They shaved his head. It&#39;s like, it didn&#39;t fall out. You cut it. You could still see it.</p>
<p>Bryan! (47:52.474)
Yeah.</p>
<p>Dave! (47:53.654)
Like, it&#39;s not as impactful. But what do they do? Someone finally decides to take him to a doctor. And what is that doctor&#39;s name? Dr. Einstein Epstein. Jesus fucking Christ. Couldn&#39;t you have picked a better, I don&#39;t know, less antisemitic name?</p>
<p>Bryan! (48:10.149)
because he&#39;s got that he&#39;s got that like Austrian accent that like everybody who&#39;s ever done like a character like a an impression of Albert Einstein does what we learn is he has the fright or harem scarum, which is what they say in the in the trailer.</p>
<p>Dave! (48:24.918)
And that is definitely not a medical term. He says it is, but it is definitely not. I am not a doctor, in case you didn&#39;t know that. But he is definitely wrong about that. But how does Michael react? He loses his fucking mind again.</p>
<p>Bryan! (48:30.413)
Yeah.</p>
<p>Bryan! (48:37.729)
crazy. He goes insane. Yeah,</p>
<p>Dave! (48:40.81)
And he&#39;s angry with the doctor like it&#39;s the fucking doctor&#39;s fault. Like, come on, man. But I mean, yeah, the whole thing is junk science. I don&#39;t think you should go to a different doctor. But still, it&#39;s not his fault.</p>
<p>Bryan! (48:44.521)
Yeah. Yep. So so.</p>
<p>That doctor is crazy as fuck. Yeah. So next, the senior hijacks county at school and interrogates him about Michael&#39;s absence, which is</p>
<p>Dave! (48:59.634)
in his office in what appears to be a janitor&#39;s closet? I mean, that&#39;s where I&#39;d put the senior too, because I mean, I honestly I don&#39;t think he should be working with children. But if I if you had to let him in, I&#39;d put him in the janitor&#39;s.</p>
<p>Bryan! (49:02.785)
Yeah.</p>
<p>Bryan! (49:06.37)
Yeah.</p>
<p>Bryan! (49:13.325)
His interest in Michael is suspect. Because apparently Michael&#39;s absent and it&#39;s only been a couple of days. This is really weird to the senior who&#39;s like, I don&#39;t know why he&#39;s so interested in the whereabouts of one of his students.</p>
<p>Dave! (49:28.982)
Yeah, so I have a note here that says that the way the kids react to the senior, it suggests that something funny is going on. And then it says, and by funny, I mean, definitely illegal and traumatizing.</p>
<p>Bryan! (49:40.737)
Yeah. So, now, Michael is haunted by nightmares about being in the spooky old house. Like, he very clearly has PTSD about ghost winos.</p>
<p>Dave! (49:54.998)
Yeah, well, I mean.</p>
<p>Bryan! (49:56.725)
Yep. And so back at school, we learn the senior has like a meeting with the school principal, and we learned that he gives children nightmares.</p>
<p>Dave! (50:07.586)
My favorite part about this though is when he goes into the office and the dog Jim jumps up on the couch and the senior goes He loves velvet. I was like, that&#39;s the greatest fucking line in the whole movie. Don&#39;t worry about him. He loves velvet I guess we all kind of do</p>
<p>Bryan! (50:19.051)
He had libed it. He must have ad-libbed that. That&#39;s such a fucking funny line.</p>
<p>Dave! (50:24.71)
And she&#39;s sitting down, she got in the woman. She&#39;s a weird character, too. She&#39;s very it&#39;s like they pulled her from some sort of British, like upstairs, downstairs sitcom and plugged her into this movie because she&#39;s like this larger woman with like a kind of blonde quaffed hair. And she says, senior, I&#39;ve had my doubts about you and I&#39;ve done some checking. And I thought, bitch, shouldn&#39;t you have done that before you hired him?</p>
<p>Bryan! (50:29.081)
Yeah.</p>
<p>Bryan! (50:35.818)
Yeah.</p>
<p>Bryan! (50:48.697)
you would think then again like my experience with teachers in the 80s was also kind of like as an adult, I think back and I&#39;m like you probably would have failed a modern day like background check just given how you how you handled other children like because what we find out about the senior is that he like he&#39;s a fraud. He&#39;s changed his name several times. He&#39;s changed his entire identity. He&#39;s</p>
<p>Dave! (51:15.178)
Yeah, you&#39;ve been thrown out of two schools, you&#39;ve faked famous paintings, and you&#39;ve changed your name and appearance four times. Like, if... if that does not scream sociopath, like, you hired a con man and you would have... Clearly, someone has told you all of this. Why didn&#39;t you ask that before you hired him? Because it&#39;s not like he presents well.</p>
<p>Bryan! (51:20.673)
He&#39;s like, he&#39;s like Freddy Krueger.</p>
<p>Bryan! (51:31.235)
I know I know so</p>
<p>Yeah. No. Oh, no, no. He&#39;s as maniacal and weird and as disheveled as Michael&#39;s dad. Yeah.</p>
<p>Dave! (51:43.126)
Yeah, and meanwhile, back at that fucking Michael&#39;s house, he is doing some Bobby Brady style drumming just to get that rage out.</p>
<p>Bryan! (51:49.141)
Yeah, yeah. Suzy is yeah. Suzy&#39;s playing the piano very well and he is just pounding on some drums like with a look of just pure anguish on his face.</p>
<p>Dave! (52:02.526)
And he says, because Connie tries to soothe him. And even then she...</p>
<p>Bryan! (52:05.249)
Yeah, Connie shows up and he&#39;s like, hey, they sacked the senior, which is a very British way to say he got fired.</p>
<p>Dave! (52:10.75)
Yeah. Hey, man, that&#39;s a Commonwealth country right there.</p>
<p>Bryan! (52:15.662)
Yeah. So, so again, Michael freaks out and he screams. He doesn&#39;t, he doesn&#39;t just say it. He screams it. Which, which no, he doesn&#39;t. He looks like a kid who shaved his head.</p>
<p>Dave! (52:21.562)
And he says, I look disgusting.</p>
<p>Jesus Christ, calm down!</p>
<p>Dave! (52:31.946)
Yeah, it&#39;s also like you&#39;re like a 12 year old boy who shaved your head. Like that&#39;s the people do that shit all the time.</p>
<p>Bryan! (52:37.113)
Kid, that&#39;s the thing. And this is, it&#39;s only been a couple of days. Here&#39;s the thing. You could touch your head and feel the growth already. Like.</p>
<p>Dave! (52:46.078)
Yeah, and Susie quite rightly says, like basically like, come on, man, he was just trying to be fucking nice to you. Which I was I was feeling the same thing, but don&#39;t worry, they have a solution.</p>
<p>Bryan! (52:52.474)
Yeah.</p>
<p>Bryan! (52:56.405)
Yeah. Uh Suzy and dad ambush Michael with a wig.</p>
<p>Dave! (53:03.254)
Yeah, and I mean, they literally like they are assaulting him with this way.</p>
<p>Bryan! (53:06.413)
This is also, this is not the last time they&#39;re going to fucking like, like hoodwink him. They&#39;re gonna, they&#39;re gonna grab him like a couple of more times in this movie. They they&#39;ve got it&#39;s the same wig. Yeah, but he doesn&#39;t want it.</p>
<p>Dave! (53:16.094)
And it&#39;s, it is the same wig he had on at the beginning. And the best part about it, the best part about the wig in general, is that he looks like Susan Tyrrell after she cuts her hair in Butcher Baker.</p>
<p>Bryan! (53:26.81)
Ah! Yup.</p>
<p>Dave! (53:29.67)
It is this choppy fucking like, are you getting like an early 90s riot girl look that is really fantastic. Maybe not on him, but.</p>
<p>Bryan! (53:36.417)
Yeah. And they&#39;re like, he&#39;s like, it&#39;s just a wig. It won&#39;t stay on. And so they go and they get some fucking glue and they glue it on and he immediately becomes like ecstatic. He finally goes. Yeah, he finally goes back to school. Not for very long. He goes back to school and he kicks ass at soccer again. But now there are bullies at this match.</p>
<p>Dave! (53:50.236)
Well, for a time, he does.</p>
<p>Dave! (54:00.03)
Yeah, there&#39;s the mean kid who looks like a good seven years older than the rest of them. Like, he&#39;s got like a mustache and the fucking biggest Cro-Magnon brow I&#39;ve ever seen, like outside of Baby Gerald from The Simpsons. And if you&#39;re counting, that&#39;s two Simpsons references so far.</p>
<p>Bryan! (54:04.009)
Yep, he&#39;s been held back.</p>
<p>Bryan! (54:10.353)
Yeah.</p>
<p>Bryan! (54:17.229)
Yeah. So, yeah. So, Michael&#39;s so good at soccer that they decide that they&#39;ve gotta do something about them, about him. And what that thing is they grab him by the hair and they rip it off.</p>
<p>Dave! (54:25.325)
Yes.</p>
<p>Dave! (54:30.206)
Yep. Yeah, in the weirdest, most unsettling scene in this entire movie, they snatch that wig, and it comes off in this slow-motion movement, when it looks like it was stuck on with, like, chewing gum, and then the kid&#39;s fucking waving it around, like he&#39;s just scalped him. It is really bizarre.</p>
<p>Bryan! (54:41.477)
with the yeah the glues the glue stretches yeah it&#39;s so fucked up the glue stretches like fucking cheese and an old pizza hut commercial like it&#39;s</p>
<p>Dave! (54:54.694)
Yeah. But then, but then even better is after that, they start taunting him with some song that is very specific to him in this situation. And I thought, when did they have time to write this song? Because this is a choreographed moment. They all seem to know the words to a song that they just made up five minutes ago.</p>
<p>Bryan! (55:08.306)
I think so.</p>
<p>Bryan! (55:15.049)
And literally everybody turns on him.</p>
<p>Dave! (55:17.546)
Yeah. Why? Because he has a terrible wig on. Now, does that happen in real life? I don&#39;t know. I&#39;m not Canadian, but I can&#39;t imagine that it does.</p>
<p>Bryan! (55:24.965)
Thanks for watching!</p>
<p>Yeah, and the way that they all sort of chase him is like the fucking, like the killer scene in prom night. Like I&#39;m surprised he doesn&#39;t fall out a window and die.</p>
<p>Dave! (55:30.487)
Yeah!</p>
<p>Dave! (55:36.884)
It really is just like, why are these children all of a sudden like bizarre maniacs?</p>
<p>Bryan! (55:44.481)
the it&#39;s a tone problem that this movie has. Uh yeah, he&#39;s got his shirt like pulled up over his head and he&#39;s like running and like ducking to get away from this like murderous mob of children who are chasing him. Good god. So, uh yeah, he&#39;s chased into his house and he resumes his uh his status</p>
<p>Dave! (56:04.906)
until that evening when he goes down into a suspiciously foggy kitchen.</p>
<p>Bryan! (56:11.525)
it&#39;s a yeah, they&#39;ve got a smoke machine that they just forgot to turn off. He finds the ghosts of the winos kicking around his kitchen. And there&#39;s a bit</p>
<p>Dave! (56:19.97)
To which I thought, why are these gross homeless ghosts just hanging out in his fucking kitchen? Like, what&#39;s this all about? Do they feel bad?</p>
<p>Bryan! (56:24.237)
Well, as she explains, ghosts can go anywhere.</p>
<p>Dave! (56:29.322)
Yep, and then he says, How did you get to be a rubby dub? And I said, What the fuck is that? Also, tacky question, Michael.</p>
<p>Bryan! (56:35.371)
Yep.</p>
<p>Bryan! (56:40.093)
Yeah, how&#39;d you fail at life so severely?</p>
<p>Dave! (56:42.786)
Yeah. You seem like maybe you&#39;re the kind of person who would die in a derelict house fire. Which is a question I&#39;m going to use at some point in the future.</p>
<p>Bryan! (56:48.389)
you. Yeah. Hey, hey, ghosts. How how <em>**</em> get so bad in your life that you died in a fire? Yeah. Um so so but the thing is the the the there&#39;s a banter but the important part here is that for some reason they have a fix for his hair problem. Uh which again will solve itself in time.</p>
<p>Dave! (56:57.333)
Jesus.</p>
<p>Dave! (57:09.35)
I sure do.</p>
<p>Bryan! (57:13.517)
But it&#39;s definitely not the problem that Michael is making it out to be. See, if he had just waited for his hair to grow back like a normal person would, none of what follows would happen.</p>
<p>Dave! (57:26.006)
Yeah, he&#39;s being a real diva about the whole situation. And at that point, I&#39;m kinda like, you know what, kid, I don&#39;t wanna help you anyway. You&#39;re lucky that these old dead winos wanna help you. I mean, yeah, they caused the problem in the first place, but still, you&#39;ve been rude.</p>
<p>Bryan! (57:31.952)
Hehehe</p>
<p>Bryan! (57:38.965)
If yeah, none of this would have happened if they hadn&#39;t been winos. Yeah. So the a ruby dub. What is that?</p>
<p>Dave! (57:41.866)
Yep. A rub- a rubby dub, as it were. So then he decides he&#39;s going to make this concoction for which she- the- the old crazy lady ghost gives him the recipe.</p>
<p>Bryan! (57:49.173)
the, here&#39;s, yeah, here&#39;s the, here&#39;s the, she gives it to him orally. He&#39;s not allowed to write it down. Here are the instructions. One really ripe banana, five dead flies, one rotten egg, three licorice leaves,</p>
<p>Dave! (58:01.33)
Eh. I quite- Who has this shit just like- Where do you even buy this stuff?</p>
<p>Bryan! (58:08.957)
I know he has kitty a fistful of kitty litter three Connie crisps whatever those are. Yep. Three crop. Yeah. Three Crosby crackers. Whatever those are. Yep. Nine spoons of soil.</p>
<p>Dave! (58:14.41)
That sounds very Canadian. And I mean, it sounds great.</p>
<p>Same, same. Oh, I think that&#39;s, I think there&#39;s a crossbar on those water crackers.</p>
<p>Bryan! (58:27.203)
Your guess is as good as mine, man.</p>
<p>Dave! (58:28.938)
I mean, aren&#39;t don&#39;t water crackers are very British, but also I think that sounds very</p>
<p>Bryan! (58:33.005)
Yeah. Nine spoonfuls of soil, a glass of pepper&#39;s fizz, again, whatever that is. And let&#39;s go with it. Yeah, yeah, glass of cheer wine. And then a spoonful of peanut butter to thicken it.</p>
<p>Dave! (58:41.299)
I think it&#39;s what you were just drinking.</p>
<p>and chickery toilet wine, same thing.</p>
<p>Dave! (58:50.012)
Oh, God.</p>
<p>Nobody has all this shit in their house.</p>
<p>Bryan! (58:56.153)
of note, uh Skippy paid for product placement in this movie. But here&#39;s the thing. Uh you have to be careful to follow the directions to the letter. If you screw it up or you use too much of one thing, bad things will happen. Also, don&#39;t get it on your hands or anything really.</p>
<p>Dave! (58:59.831)
Yes they did.</p>
<p>Dave! (59:14.518)
Yeah, and he does not heed that advice. Neither does Connie in a very upsetting moment that we will get to.</p>
<p>Bryan! (59:17.209)
no but well at oh no yeah so Michael sets about to make the solution while dad and yes while dad and Suzy watch and they&#39;re like what the fuck is he doing and so just as he&#39;s so he&#39;s got it and he&#39;s got it in a blender and just as he&#39;s about to put it on his head the two of them again ambush him and dump it down the toilet thinking that he&#39;s going to drink it and so now</p>
<p>Dave! (59:24.47)
By candlelight, no less.</p>
<p>Dave! (59:42.726)
It&#39;s basically what she made him for breakfast, so I don&#39;t know what the fucking problem is.</p>
<p>Bryan! (59:44.969)
Yeah. So, with the recipe lost now, Michael can&#39;t make anymore but the ghosts visit him again and the dude Wino steals the recipe from the chick Wino who is sometimes nice and sometimes mean and he gives it to Michael. So, now, he gets to baking another batch and this time, nobody stops him and he happily slaps it on his head but it&#39;s very</p>
<p>Dave! (01:00:08.254)
And he says, I&#39;m a better painter than Dad, and I thought, well, maybe.</p>
<p>Bryan! (01:00:12.921)
I mean, we&#39;ve seen these paintings. But see, here&#39;s the thing is the solution, the peanut butter solution, you might say it&#39;s. Yep, it&#39;s too thin. So he uses more peanut butter rookie move. And he says, yeah, I&#39;ve got to use more peanut butter. It&#39;s the only solution. Yeah. So now with the stuff thicker, it sticks to his head. And then.</p>
<p>Dave! (01:00:16.534)
Yeah.</p>
<p>Dave! (01:00:20.758)
Ding ding ding!</p>
<p>Dave! (01:00:34.058)
There it goes.</p>
<p>Dave! (01:00:39.946)
Meanwhile, the rabbit is still trying to buy this weird paint.</p>
<p>Bryan! (01:00:42.637)
Yeah, in the morning, there&#39;s stubble on his head, as happens with everyone who shaves their head, mind you, and everybody acts like it&#39;s a fucking miracle.</p>
<p>However, it is a miracle because every time they cut away and then cut back to Michael, there&#39;s more hair on his head.</p>
<p>Dave! (01:00:59.686)
And again, I say this is a health problem. Take him to a doctor and not that other guy. He doesn&#39;t know what he&#39;s doing. Take him to a real doctor.</p>
<p>Bryan! (01:01:04.024)
Yeah.</p>
<p>Bryan! (01:01:08.306)
I would like to see Dr. Epstein&#39;s medical license.</p>
<p>Dave! (01:01:11.094)
Yeah, well, you can&#39;t, because he&#39;s a Jewish stereotype. And not a doctor.</p>
<p>Bryan! (01:01:13.573)
the bathroom. Yeah. In the bathroom, Michael shows Connie the solution. And and Connie immediately plots to grow pubes with the stuff which again will happen naturally with time. I don&#39;t I don&#39;t because it doesn&#39;t go anywhere. It there&#39;s a scene later on that I&#39;m like, oh, is that how you make the hair?</p>
<p>Dave! (01:01:21.408)
Oh, God. I don&#39;t like this.</p>
<p>Dave! (01:01:32.246)
Yeah, I don&#39;t care for this part at all. It&#39;s very upsetting.</p>
<p>Dave! (01:01:39.81)
That makes it even fucking weirder.</p>
<p>Bryan! (01:01:43.765)
He. Fuck it. We&#39;ll get there. I&#39;m going to leave that. I&#39;m going to let that end.</p>
<p>Dave! (01:01:46.314)
So here&#39;s, let&#39;s revisit what is a horror movie because we should come back to this because so far we&#39;ve got frightening wino ghosts. We have a possibly dead mother. You have a disheveled father. You have bullies at school. It&#39;s a very, they&#39;re all gonna laugh at you thing going on here. And now you have puberty. Like these are all terrifying things that in the abstract for children are really frightening. But as an adult through this weird fucking lens, it&#39;s just like...</p>
<p>Bryan! (01:01:53.347)
Hehehehe</p>
<p>Bryan! (01:02:03.213)
Yep.</p>
<p>Dave! (01:02:14.442)
What is, who wrote this movie? Why is this so weird?</p>
<p>Bryan! (01:02:16.693)
Yeah. Yeah, there&#39;s actually, I believe there&#39;s actually like three or four writer credits to this movie, which again makes a lot of sense when you put it all together.</p>
<p>Dave! (01:02:24.598)
Yeah, I can tell. And that doesn&#39;t include like the other people who did like punch up, if there was any. Cause if someone did punch up, all it did was make the movie weirder.</p>
<p>Bryan! (01:02:32.205)
Yeah, yeah.</p>
<p>Bryan! (01:02:36.577)
Yeah. So, Michael is now like is now like ecstatic. This isn&#39;t like the wig situation. He&#39;s</p>
<p>Dave! (01:02:42.762)
Because by the time he gets to school, he&#39;s got some real Sean Cassidy hair.</p>
<p>Bryan! (01:02:46.177)
Yeah, but first he&#39;s going to call his mother and through this, we learn what the country code for Australia is, which if the Simpsons have taught us anything and they haven&#39;t a phone call to Australia is expensive and will result in a booting.</p>
<p>Dave! (01:02:59.222)
$500 dues. That&#39;s the third Simpsons reference.</p>
<p>Dave! (01:03:06.462)
But that again, it&#39;s more like a no look everybody. She&#39;s not dead like they all feel really like fucking shoehorned in and The mo and every time it just like it all feels like either reshoots or ADR because it&#39;s like these weird moments that are kind of like Sort of like outside of the plot but shoved in like he climbs into bed with his father at one point He&#39;s really upset. It&#39;s just like what is this fucking scene?</p>
<p>Bryan! (01:03:09.995)
Yeah.</p>
<p>It really is just like stuffed into the cracks.</p>
<p>Dave! (01:03:33.638)
It would make sense if he was upset because the mother was dead. It doesn&#39;t make sense that he&#39;s so goddamn traumatized that his hair fell out. Like, I get that, like, kids get scared of shit, but like, I don&#39;t know. I mean, you know, what&#39;s the scary thing here? What is the what is the death and loss thing? Is it hair? Is this an allegory? What&#39;s happened?</p>
<p>Bryan! (01:03:41.325)
Yeah, yeah.</p>
<p>Bryan! (01:03:47.103)
Yep.</p>
<p>I know, I know.</p>
<p>Bryan! (01:03:55.493)
hair. Yeah. Is it hair? I mean, now has a mane of luxurious</p>
<p>Dave! (01:04:02.774)
Yeah, I told you, real Sean Cassidy, real kind of a, what&#39;s that one where he&#39;s got the silk jacket, the do-run-run, that it&#39;s that album. Yeah, he&#39;s got that real kind of feathered, beautiful, very beautiful wig.</p>
<p>Bryan! (01:04:06.389)
It&#39;s a, uh, the, to do run run. Yeah.</p>
<p>Bryan! (01:04:13.313)
Yeah, yeah, it&#39;s a wonderful wig. I, you know, I credit them.</p>
<p>Dave! (01:04:16.342)
I have been, I decided I want to have another podcast because every time I watch television, I will turn to whoever is watching television with me. And that&#39;s very few people who I watch television with. And I say, you think that&#39;s a wig? And every single time Michael goes, yes, it&#39;s a wig. And I think, I want to have a podcast that&#39;s just called, is it a wig? And I&#39;ll just have people on and we&#39;ll just talk about movies with wigs.</p>
<p>Bryan! (01:04:25.477)
Thanks for watching!</p>
<p>Bryan! (01:04:33.689)
My man.</p>
<p>Bryan! (01:04:40.401)
You know what? We&#39;re going to do samurai cop because the answer to that question is yeah, that&#39;s a wig.</p>
<p>Dave! (01:04:45.538)
Well see that&#39;s the thing is every time I&#39;m like well see I&#39;m right though. So let&#39;s talk about is it a wig? In this movie there is no shortage of wigs.</p>
<p>Bryan! (01:04:50.597)
this in a wig. Yep. Oh, there&#39;s a variety of them. As a matter of fact.</p>
<p>Dave! (01:04:56.278)
You&#39;ve got Butcher Baker Nightmare Maker wig. You&#39;ve got Sean Cassidy Do-Ron-Ron wig. You&#39;ve got, it just goes on and on.</p>
<p>Bryan! (01:05:00.796)
Yeah. Yep. So, so at home, so, so the thing with the school scene is basically to establish that the hair is growing between before his very eyes and it&#39;s now it&#39;s a distraction.</p>
<p>Dave! (01:05:13.962)
And how does he prove it? Because the girls are like, that&#39;s not your hair. And I&#39;m like, why the fuck are all these kids so concerned about whether it&#39;s his real hair or not? Get a life, ladies. And then he&#39;s like, look, he has dandruff. Like, this kid can&#39;t catch a fucking break.</p>
<p>Bryan! (01:05:21.997)
Yeah. Get a grip. Yeah.</p>
<p>Bryan! (01:05:28.229)
night. Yep. So, at home that night, Michael comes in looking like cousin ant and dad is again, this is what I talked about earlier. Dad is impressed instead of being absolutely</p>
<p>Dave! (01:05:40.758)
Yeah, I mean, this is concerning because he went to school that morning and he did not have Sean Cassidy hair, nor did he look like cousin. Now he&#39;s back. It&#39;s time for dinner. What did Dad make Toronto noodle soup? What the fuck is that? Did I look it up? Yeah. Is there a recipe? No, there&#39;s not even a mention of it. It&#39;s just like, what are the best noodle soup places in Toronto? Now, is that good information? Sure. Is it what I needed in the moment? No.</p>
<p>Bryan! (01:05:53.749)
What is that?</p>
<p>Bryan! (01:05:57.909)
No, no.</p>
<p>Bryan! (01:06:07.781)
the pot down. Nope. I, you know what? I went when they, when he puts the pot down like having watched this a couple of times, I&#39;m like, I bet you it&#39;s just like egg noodles and water and like a bullion cube. Like, that&#39;s probably it because that strikes me. That strikes me as the only thing that this man is capable of making. Yeah.</p>
<p>Dave! (01:06:20.174)
I don&#39;t know, that sounds like American noodle soup.</p>
<p>Dave! (01:06:26.742)
Yeah, because he is a complete waste of space. He&#39;s a bad father, he&#39;s a mediocre painter at best.</p>
<p>Bryan! (01:06:31.298)
Yeah, the-</p>
<p>Yeah, the thing, the thing about</p>
<p>Dave! (01:06:36.494)
There&#39;s a medical crisis unfolding in front of him and he thinks it&#39;s a great thing.</p>
<p>Bryan! (01:06:39.161)
this is a legit problem and nobody reacts to this the way that you expect them to. So, the hair is now such.</p>
<p>Dave! (01:06:47.582)
No, but he does apologize to Connie, and I thought, well, that&#39;s the first good thing you did.</p>
<p>Bryan! (01:06:53.659)
Yeah. Cuz Connie&#39;s just Connie&#39;s doing a lot more for</p>
<p>Dave! (01:06:58.402)
Yeah, cause the dad&#39;s just like, your hair is young and frisky, and I was like, what the fuck does that mean? What is wrong with you? You&#39;re a bad father!</p>
<p>Bryan! (01:07:05.121)
Yep. So the hair is now such a problem at school that Connie has to cut it as it grows and this</p>
<p>Dave! (01:07:11.798)
Now, but let me be honest with you, fucking every kid in that classroom could use a haircut.</p>
<p>Bryan! (01:07:16.265)
Yeah, yeah.</p>
<p>Dave! (01:07:19.082)
I mean, they all looked like me when I was eight, just like fucking cowlicks out left and right. Everyone looks terrible. This is when people, when younger people are like, ah, the eighties, everything was so cool and hip and colorful. No, it wasn&#39;t. Everything was fucking gross and earth tones. Everyone had bad hair. It looks like they cut it themselves. That&#39;s what the eighties looked like.</p>
<p>Bryan! (01:07:23.881)
Oh, yeah. Yeah. I&#39;m telling you. I</p>
<p>Bryan! (01:07:36.245)
Oh, yeah, I had the worst fucking hair that you can imagine. Just the fucking worst all the way through the 80s. And it didn&#39;t really get much better after that. And then I lost it.</p>
<p>Dave! (01:07:44.61)
You know what it is? You know what your hair looks like in the 80s? It looked like you remember the beginning of sleepaway camp with the kids driving the boat? That&#39;s what your hair looks like in the 80s. You looked like that kid in the 80s. Why? Somebody help the people.</p>
<p>Bryan! (01:07:51.541)
Yeah. Somebody help the people. So,</p>
<p>Bryan! (01:08:02.521)
like he Michael cops to using the solution but understandably</p>
<p>Dave! (01:08:06.818)
But they also keep getting yelled at by the teachers. It&#39;s like, okay, so the dad&#39;s not concerned because she&#39;s a bad parent, obviously. The mom&#39;s not dead, so she&#39;s not really worried about it. It&#39;s like, Connie&#39;s the only one who seems to be taking this seriously because even the teachers are not, they&#39;re like, you are being disruptive. And it&#39;s like, what is wrong with all of you people? Why are you gaslighting him?</p>
<p>Bryan! (01:08:25.005)
Yeah, and that&#39;s the thing is he pleads with the teacher to be educated. He wants to be educated, not just Harry, he says, but this movie has a really weird relationship with school. And I think that it&#39;s kind of consistent with the with the 1980s because there was a real sort of anti establishment vibe to a lot of these children&#39;s movies at the time because they were being written by like post hippie boomers. So teach</p>
<p>Dave! (01:08:32.662)
Which, fair.</p>
<p>Dave! (01:08:51.586)
the ones that hadn&#39;t flipped to Republicans.</p>
<p>Bryan! (01:09:06.005)
the</p>
<p>Dave! (01:09:09.762)
but also like weirdly aloof.</p>
<p>Bryan! (01:09:13.373)
Yeah. With the exception of the senior, I would say like the teachers just seem kind of like rash and like sort of quick to judge but they&#39;re not bad guys, you know?</p>
<p>Dave! (01:09:26.55)
But I think this is what makes this an interesting movie because to a child, this shit probably makes a lot of sense. Like where your teachers are an authority figure who you mostly have an adversarial relationship with, like, you know, under most circumstances. But to an adult, it&#39;s like, this is bonkers. What is this movie? But like to a kid, like the fears seem real and rational. The fact that like your teachers aren&#39;t listening to you and are just sort of like either condescending to you or dismissing you.</p>
<p>Bryan! (01:09:40.451)
Yeah.</p>
<p>Dave! (01:09:56.426)
It&#39;s like it must, and again, I don&#39;t remember what it was like to watch it, but it must&#39;ve felt more like a, yeah, this all makes sense, but to me, I&#39;m like, none of this makes sense. And it&#39;s really weird that a kid would think that it does.</p>
<p>Bryan! (01:10:08.705)
Yeah. It um it feels like really kind of consistent with the way that like when Nickelodeon was really new as like a children&#39;s property, it was very yeah, most of their programming. Yeah, like most of their programming was Canadian at the to beginning. It&#39;s cheap, man. Um so yeah.</p>
<p>Dave! (01:10:18.102)
Which was also extremely Canadian, even though it was from like Ohio or whatever.</p>
<p>Much like today, most things were shot in Canon.</p>
<p>Dave! (01:10:31.41)
Okay, the teachers say they will resign if he goes back to the classroom. What?</p>
<p>Bryan! (01:10:35.909)
right? What the <em>**</em> is wrong with you people? So, that night, Connie tosses and turns because his mutant pubes are growing out of control and are making his legs itchy and then he yells at them to stop and they do.</p>
<p>Dave! (01:10:51.55)
It&#39;s like that movie. What&#39;s the one where they&#39;re at the pyramid in Mexico? Ruins with the vines that are trying to kill them. It&#39;s that. Except it&#39;s cubes and Canadian 10 year old or whatever. It&#39;s very I don&#39;t I don&#39;t like I don&#39;t like any of it. I don&#39;t like any.</p>
<p>Bryan! (01:10:57.669)
The ruins, yeah. Yeah.</p>
<p>Bryan! (01:11:09.777)
hair. Yeah. So, is this well, is this the trick to making the never, he does not mention it.</p>
<p>Dave! (01:11:13.554)
And how come he doesn&#39;t say that to the other kid?</p>
<p>Dave! (01:11:19.542)
I&#39;ll tell you why, because they passed the script off to someone else who was like, ah, I don&#39;t like that. It&#39;s like when you watch cooking shows and like a top chef and they&#39;ll do a thing where like they&#39;re working as a team but they can&#39;t see what the other people are doing. So then they just kind of have to pass off the recipe to someone else. And they&#39;re like, I guess I got to figure it out based on what&#39;s in front of me. It&#39;s that. And so they&#39;re just like, you know what, I don&#39;t like that part. I&#39;m gonna start my own thing. I&#39;m an arrogant asshole, I&#39;m gonna start.</p>
<p>Bryan! (01:11:24.515)
Yeah.</p>
<p>Bryan! (01:11:36.961)
Oh sure, yeah, Master Chef Tag Team.</p>
<p>Bryan! (01:11:43.989)
Yeah. Back at his own house, Michael has to keep his hair lifted with this elaborate contraption for some reason. And he has</p>
<p>Dave! (01:11:54.406)
It is very strange. It&#39;s like the it&#39;s like the sash from the curtains or something like that. And he ties it in it.</p>
<p>Bryan! (01:11:59.957)
Yeah. That&#39;s like in a bow at the end and it&#39;s like this like onion of hair that just like rests on top of his head.</p>
<p>Dave! (01:12:09.046)
I think they were just like, that&#39;s a great visual. Let&#39;s do that. Because then he just sort of sits up and falls asleep. He&#39;s like, he&#39;s a horse. He just falls asleep. I don&#39;t think that&#39;s true. I think horses actually do lay down. I don&#39;t think anything stands up to sleep. That seems like a bad idea.</p>
<p>Bryan! (01:12:11.706)
Yeah. Yeah, it&#39;s.</p>
<p>Bryan! (01:12:16.833)
Yeah.</p>
<p>Bryan! (01:12:24.038)
the house again. No. But he has now he has nightmares about the spooky house again and these yes and these ones are terrifying. Um it&#39;s all like heavily polarized footage of the house that runs in like slow mo while dogs just bark on</p>
<p>Dave! (01:12:27.938)
and about the dog.</p>
<p>Dave! (01:12:42.07)
Yeah, it feels positively German.</p>
<p>Bryan! (01:12:49.305)
the</p>
<p>Dave! (01:12:55.262)
Yeah. And then and then he wakes up, he&#39;s like under a house.</p>
<p>Or is that later?</p>
<p>Bryan! (01:13:02.277)
Uh no, that that happens next. So, yeah, the next morning with his hair trailing on the ground, Michael&#39;s been kicked out of school. We find out and he intends to go anyways which what the <em>**</em> school. It&#39;s it&#39;s his hair.</p>
<p>Dave! (01:13:18.122)
I mean, I think this really resonates with us today, where they&#39;re just like, you can&#39;t talk about black people or queers, and if you do, we&#39;re kicking you out of school. And they&#39;re like, we just wanna learn what is wrong with you. And I say, I ask the same question.</p>
<p>Bryan! (01:13:20.726)
Yeah.</p>
<p>Bryan! (01:13:31.677)
Yeah. So, on the way to school, the, for some reason, the wind is so strong that he&#39;s getting like blown all over the place. His hair is getting blown all over the place and he finds shelter next to a neighbor&#39;s house and falls asleep. Yeah. So, unfortunately, this leaves him vulnerable to being kidnapped by an unseen French person which this if I were a young child watching this</p>
<p>Dave! (01:13:47.464)
under the house.</p>
<p>Bryan! (01:13:59.501)
that might actually be a little frightening to me.</p>
<p>Dave! (01:14:01.746)
Yes, because for context, everybody, the biggest fear a child had in the 80s was being kidnapped. Now, now I know, I often say kidnapping was really hot in the 80s. Kidnapping was having its fucking best life in the 80s. I don&#39;t know if it was actually more children were being kidnapped. I think it&#39;s really more of like, people heard about it a lot more, because it was all rage.</p>
<p>Bryan! (01:14:08.557)
Stranger Danger.</p>
<p>Bryan! (01:14:26.325)
Oh, sure. It&#39;s it&#39;s like yeah, it&#39;s like today. You know, it&#39;s the</p>
<p>Dave! (01:14:29.826)
But in the 80s, kidnapping was real hot and there were fucking TV movies about it. It was like happened on TV shows. Remember when Tootie runs away and goes to New York and they try to like kidnap her and like sex traffic her. Like that was all the special episodes where like you are going to be kidnapped, raped and sold into slavery.</p>
<p>Bryan! (01:14:37.473)
Oh yeah!</p>
<p>Bryan! (01:14:49.249)
Yeah, what was that one? I think I know my first name is Stephen.</p>
<p>Dave! (01:14:52.006)
I know my first name is Stephen, the movie about Stephen Stainer, who had what is probably the saddest life ever, who was kidnapped, then heroically saves another boy from these pedophiles only to die like four years later in a motorcycle accident and his brother was a serial killer.</p>
<p>Bryan! (01:14:54.659)
Yeah.</p>
<p>Bryan! (01:14:58.515)
the worst</p>
<p>Bryan! (01:15:11.625)
the worst fucking life possible. Yeah, yeah. Think about that every now and then when it comes to, yep. Yep. So yeah, so.</p>
<p>Dave! (01:15:12.682)
What. The. Fuck.</p>
<p>Dave! (01:15:18.474)
Yeah, that&#39;s the 80s. And then in a nutshell, that is the 80s.</p>
<p>And that&#39;s what happens to Mike.</p>
<p>Bryan! (01:15:26.401)
Yeah. So now back home, dad is tormented with grief as like news headlines spin in to uh to</p>
<p>Dave! (01:15:34.206)
Oh, you forgot to mention that the senior is wearing a coat made entirely of hair.</p>
<p>Bryan! (01:15:40.053)
Oh yeah, yeah. When that when he comes back around because they haven&#39;t everybody knows they&#39;ve not shown him yet, but like everybody knows who kidnaps him. But yeah, like later on, he&#39;s going to show up with an entire coat like a big fucking coat made of hair.</p>
<p>Dave! (01:15:43.926)
Oh, have they not shown him yet?</p>
<p>Bryan! (01:15:55.305)
But yeah, but back home, Dad is tormented with grief as news headlines spin into frame announcing Michael&#39;s disappearance and he&#39;s like trashing his like cartoonishly trashing his studio and then like more news headlines spin in like announcing more child disappearances and he like furthermore like trashes his studio and then later we find out the kidnapper is a senior who has Michael in this weird coffin thing.</p>
<p>Dave! (01:16:22.954)
and 20 kids are missing from school. In the span of, I don&#39;t really know what the timeline is here, but yeah, it&#39;s like all of a sudden, why are so many kids missing? Why has nobody done this? Like, why was no one doing anything? Yeah, and everyone&#39;s real fucking like, blase about everything. Like, Connie&#39;s just like, well, they took Mei-Lang, my sister, but hey, what are you gonna do?</p>
<p>Bryan! (01:16:25.208)
Yes.</p>
<p>Bryan! (01:16:28.534)
It&#39;s almost like it happens overnight.</p>
<p>Bryan! (01:16:35.273)
including Connie&#39;s including Connie&#39;s sister.</p>
<p>Bryan! (01:16:41.449)
super nonchalant about all these missing children.</p>
<p>Bryan! (01:16:48.407)
Yeah.</p>
<p>Dave! (01:16:48.842)
We should get the adults, and Susie says, well, Dad&#39;s no use. Yeah, no shit, Susie. Your dad is a fucking train wreck. Nothing about that man says he&#39;s gonna be good in a crisis.</p>
<p>Bryan! (01:16:53.097)
No shit.</p>
<p>Bryan! (01:16:59.941)
that&#39;s a good question. Yeah. like, well, we gotta do later but they immediately something about it and they go gift for her dad in order to the clerk gives them a new bristles and they&#39;re wrecking</p>
<p>Dave! (01:17:18.634)
Uh, yeah, look, I&#39;m not a painter. I don&#39;t know much about creating art. Clearly I have a podcast. It seems like the least effective brush you could possibly buy. I mean, it is clearly made from human hair.</p>
<p>Bryan! (01:17:30.922)
Oh yeah, it&#39;s all floppy and shit.</p>
<p>Yeah. Oh yeah. Well, he&#39;s I think he says it or rather they recognize it as Michael&#39;s hair.</p>
<p>Dave! (01:17:39.122)
If someone was like, hey, you can buy this paint brush that&#39;s made from human hair, you can use it to make paintings. I would say, get away from me right now. I don&#39;t like you being near me. You frighten me.</p>
<p>Bryan! (01:17:52.192)
the</p>
<p>Dave! (01:18:05.27)
But he&#39;s wearing a giant coat made of human hair. So kind of recognizable.</p>
<p>Bryan! (01:18:07.941)
hair. Yeah. Uh but then they they kind of figure it out that it&#39;s the senior and so</p>
<p>Dave! (01:18:14.766)
Yeah, because Susie is touching the hairbrush and she goes, I know this hair. And I said, what? What do you mean you know this hair, you goddamn weirdo?</p>
<p>Bryan! (01:18:18.981)
Yeah.</p>
<p>Well, she&#39;s been she&#39;s she&#39;s been she&#39;s been cutting it for like, I don&#39;t know, weeks.</p>
<p>Dave! (01:18:28.302)
Either two weeks or six years, it does, who knows? Who knows how long this movie takes to unfold?</p>
<p>Bryan! (01:18:32.293)
Yeah. Yeah. So together, Connie and Susie stake out the art supply store until the senior arrives to drop off more brushes and then the senior makes a weak attempt to kidnap Susie. Uh but while that&#39;s happening, Connie sneaks into the back of his truck.</p>
<p>Dave! (01:18:47.598)
I will say though, I will say they&#39;re standing outside the art supply store. My first thought, because he has a funnel and a big bag of sugar, and my first thought was they&#39;re gonna put sugar in his gas tank. Bingo bango, sugar in the gas tank. Your ex-husband strikes again. That&#39;s number four, everybody.</p>
<p>Dave! (01:19:07.406)
But I will say like this is their plan. I mean, it&#39;s a... it&#39;s not a bad plan.</p>
<p>Bryan! (01:19:07.717)
So.</p>
<p>Bryan! (01:19:12.901)
I&#39;m just gonna leave like a trailer breadcrumbs.</p>
<p>Dave! (01:19:15.982)
But he says, so the senior kind of sidles up to her in this real fucking creepy moment. He&#39;s like, hello, little girl. Do you want to come for a ride?</p>
<p>Bryan! (01:19:25.733)
wearing a suit made of human hair.</p>
<p>Dave! (01:19:28.686)
Now, everything about that says no I don&#39;t, but it is also the laziest kidnapping technique.</p>
<p>Bryan! (01:19:34.973)
It is because also she&#39;s like, I&#39;m good. And he&#39;s like, I tried. No, so</p>
<p>Dave! (01:19:38.094)
Yeah. But it didn&#39;t really, though. I mean...</p>
<p>Somehow you&#39;ve been successful enough to kidnap, like, I guess 20 plus children have fallen for this, so some of them are, uh, real dumb.</p>
<p>Bryan! (01:19:49.105)
Yeah. Oh, no. We&#39;re going to find out. We&#39;re going to find out how he gets them and that part also kind of pumps me out. Yeah. So, so now the truck takes off and Connie is hidden in the back and what he&#39;s doing is he&#39;s</p>
<p>Dave! (01:19:53.998)
Oh yeah, no, that&#39;s some REAL creepy shit.</p>
<p>Dave! (01:20:02.286)
This is when we get the first hot, hot Celine Dion song.</p>
<p>Bryan! (01:20:05.477)
Yeah, yeah. So he&#39;s like pouring sugar out the back to like leave a trail while the Celine Dion song plays. It&#39;s got lyrics. It&#39;s got lyrics like bingabanga dinga danga. Your mind goes clang clang. Now you&#39;re off to Hong Kong and shit.</p>
<p>Dave! (01:20:11.374)
It&#39;s a real Scooby Doo moment.</p>
<p>Uh huh.</p>
<p>Dave! (01:20:19.502)
The whole thing feels like, this is like real made for TV.</p>
<p>Bryan! (01:20:23.301)
like like I&#39;m trying I don&#39;t want to rag on it because she was like a teenager when she wrote the song but uh</p>
<p>Dave! (01:20:31.342)
And then in the meantime, she&#39;s as soon as he&#39;s following behind on her bike. And and then there&#39;s like a bunch of hobos cat calling her. And it&#39;s like, why, why is she right? First of all, wouldn&#39;t he see her in the rearview mirror? But also, why are these old men yelling at a 10 year old girl on a bike?</p>
<p>Bryan! (01:20:51.781)
that&#39;s right. Because there are like all these people who are just sort of like menacingly hanging around and like, yeah, I don&#39;t I don&#39;t know. I don&#39;t get it. They&#39;re in the bad side of town. If if there if there is such a thing in Montreal.</p>
<p>Dave! (01:21:04.001)
I don&#39;t know, I think in Canada that&#39;s called Edmonton.</p>
<p>Bryan! (01:21:06.373)
Yeah, in your face Edmonton. Yep.</p>
<p>Dave! (01:21:08.814)
Yeah, suck it, Edmonton! The rest of Canada&#39;s better than you. You&#39;re the America of Canada.</p>
<p>Bryan! (01:21:13.205)
So, so, so she loses the trail like there&#39;s like a street cleaner or something that like washes it away and so she&#39;s like basically forced to kind of give up on it but Connie is a</p>
<p>Dave! (01:21:26.67)
Oh, that was the scene where the close caption refers to them as winos. And I thought, I thought good work, Evren.</p>
<p>Bryan! (01:21:30.309)
the white wine.</p>
<p>Dave! (01:21:41.614)
This is when he drags him into the Senor&#39;s lair. And it looks like he lives on the set of the Take On Me video.</p>
<p>Bryan! (01:21:44.997)
Yeah, yeah.</p>
<p>Bryan! (01:21:49.009)
Yeah. So what we find is that the seniors workshop is full of captive child slaves who are making brushes out of Michael&#39;s hair and there&#39;s</p>
<p>Dave! (01:21:57.294)
Now, is this a this is a direct Temple of Doom thing, right? Because that is from here on out, this is Temple of Doom right down to the Asian kid.</p>
<p>Bryan! (01:22:01.413)
it has to be. It has to be because that was that was the year before. Yes. Yes. That was the year before this. That was eighty-four. So, um cuz there&#39;s also there&#39;s like uh and that&#39;s right and there&#39;s children, there&#39;s child slaves in like cages and <em>**</em> like in that movie.</p>
<p>Dave! (01:22:18.222)
Yeah, this is like, it&#39;s just a sweatshop, which again, kind of an 80s fear. Nobody cared when it actually happened, but when it happened in movies, it was terrible.</p>
<p>Bryan! (01:22:22.853)
the</p>
<p>Dave! (01:22:33.39)
But they&#39;re also even they&#39;re kind of like, you know, it sucks, but what are you gonna do about it? I guess we live here now</p>
<p>Bryan! (01:22:38.341)
Yeah. Yeah. So, the senior apparently is feeding Michael a yogurt only diet. I don&#39;t because he feeds it to him.</p>
<p>Dave! (01:22:45.626)
I did not care for this at all. This moment was really upsetting. Yeah, and it&#39;s like, you know, it&#39;s like plain yogurt and he&#39;s like laying in this weird iron lung looking thing. It is like a deeply disturbing visual.</p>
<p>Bryan! (01:22:56.901)
Yeah, and what? Yeah, like Michael is basically forever in state just lying there in this like coffin thing as his hair grows down a funnel behind him and the senior spoon feeds him plain yogurt.</p>
<p>Dave! (01:23:14.862)
Did you see the black phone? So, okay, so the black phone, where there&#39;s a heavy implication of sexual abuse, but they never actually say it or anything, that&#39;s what this kind of gives is like this weird, bizarrely intimate, deeply disturbing sexual implications. I was very upset about this whole moment.</p>
<p>Bryan! (01:23:17.295)
Yeah.</p>
<p>Bryan! (01:23:32.901)
Yeah. Like I think that I think that it&#39;s supposed to be like kind of like a like a like a fairy tale quality to it. But because it has none of the fairy tale trappings and it&#39;s all just very modern and weird and in and in this like child slave sweatshop, it&#39;s just like the implication is so much more dire.</p>
<p>Dave! (01:23:47.79)
It&#39;s just terrifying.</p>
<p>Dave! (01:23:56.814)
But you know what the best part was? Even the dog has a hair coat.</p>
<p>Bryan! (01:24:00.229)
Yep. I gotta tell you, I like the dog.</p>
<p>Dave! (01:24:04.238)
Yeah, the dog&#39;s pretty, he&#39;s pretty great. And this is when we find out the darkest fucking part of this movie.</p>
<p>Bryan! (01:24:06.021)
Yeah. So, so.</p>
<p>Bryan! (01:24:10.725)
So, yeah. So, Connie gets captured and he starts to talk to all the other kids and he&#39;s like, how&#39;d you get them? And the kids are like, the senior is basically roofing the children with</p>
<p>Dave! (01:24:20.854)
Yeah, allow me. Through lies, through hypnosis, through ice cream that puts you to sleep. Oh man, I didn&#39;t like that at all.</p>
<p>Bryan! (01:24:27.941)
I was like, I was like, bags of No, I, when I heard that, I was like, cuz cuz I again, watched it a couple of times in reasonable close proximity the second time I was paying attention and that line really stuck out to me. It also made me think of bags of powder which make you sleepless.</p>
<p>Dave! (01:24:45.006)
But you&#39;re sleepless. Pal Friendly comes by. Yeah, and meanwhile, all the kids are wearing what I will describe as Heaven&#39;s Gate robes.</p>
<p>Bryan! (01:24:55.333)
Yeah, they&#39;re all like in a</p>
<p>Dave! (01:24:57.422)
These sort of like weird peach robes?</p>
<p>Dave! (01:25:04.942)
No, this is all, whoever wrote the last part of this movie, I don&#39;t know if they ever worked again, but Jesus Christ.</p>
<p>Bryan! (01:25:13.317)
that they that that that the end of their career coincides with the beginning of a stint in prison. Yeah. So, so, at the same time as all this darkness is is dawning upon us, we learned that the senior is painting these crazy magical paintings that you can actually out of nowhere. It just yep and you can walk into these paintings and it and it&#39;s only</p>
<p>Dave! (01:25:18.318)
Yeah, god damn</p>
<p>Dave! (01:25:29.998)
out of nowhere. This comes out of nowhere. All of a sudden, there&#39;s magic involved.</p>
<p>Bryan! (01:25:43.781)
paintbrushes.</p>
<p>Dave! (01:25:45.102)
And he does it in this very whimsical...</p>
<p>Bryan! (01:25:49.317)
like, oh, yeah, yeah. Like, we&#39;re gonna get there and actually see him do it in a strange turn of character but first, we gotta, we gotta, we gotta sort of connect a few dots here because we&#39;re way off track now. Yeah. So, back home, we find out that the doctor who&#39;s just hanging out with Michael&#39;s dad, his brother is also the rabbit and</p>
<p>Dave! (01:25:55.246)
Yeah.</p>
<p>Dave! (01:26:00.782)
Yeah. Yeah, the murder mystery is afoot.</p>
<p>Dave! (01:26:13.294)
And this is when my note says, who the hell is the rabbit? Because I must have missed earlier on that that is the guy&#39;s name in the painting. Why is he the rabbit and the senior also? Why do all three of them have different accents?</p>
<p>Bryan! (01:26:17.349)
Yeah, he&#39;s the...</p>
<p>Bryan! (01:26:24.069)
I don&#39;t know.</p>
<p>Bryan! (01:26:28.837)
the doctor. Right. Yeah II assume just based on his behavior. The doctor got hit on the head with something and that&#39;s just what he does. We also but we also find out that that they also have another brother, a dude named Sergio who now has no name and only goes by the name the senior. So, now they can use him to track down the senior and so back at the senior&#39;s child slave camp.</p>
<p>Dave! (01:26:54.03)
But also like, how is the senior his brother? It&#39;s clearly a con man. It is canonically established that he is a con man who has changed his identity like seven times. So presumably he&#39;s moving around a lot. This is all very convenient. I&#39;m guessing when Michael Rubo conceived of this, he didn&#39;t really have an ending.</p>
<p>Bryan! (01:26:57.349)
Because</p>
<p>Bryan! (01:27:06.213)
bounced around locations. Yeah, so as to not as to evade the law.</p>
<p>Bryan! (01:27:18.757)
like, I get the feeling that there was a certain point where they were like, okay, write all this <em>**</em> down and then he wrote it down and they&#39;re like, okay, let&#39;s get into production and then they look at the script and went, dude, there&#39;s no ending</p>
<p>Dave! (01:27:30.926)
Yeah, someone was just like, guys, this is like a 98 minutes and we&#39;re nowhere near wrapping this shit up. They were like, I don&#39;t know, how about these two convenient facts that solve the whole fucking-</p>
<p>Bryan! (01:27:37.765)
Yeah.</p>
<p>Bryan! (01:27:42.885)
the</p>
<p>Dave! (01:27:47.662)
Yep. All the Heaven&#39;s Gate members are asleep in their hammocks. Heh. Like it&#39;s a goddamn submarine.</p>
<p>Bryan! (01:27:51.077)
the but but I will say this looks pretty comfortable so Connie wakes up and he plots to go see Michael but he finds out that the senior has this</p>
<p>Dave! (01:27:59.086)
Yeah.</p>
<p>Bryan! (01:28:17.189)
ring that he wears that looks like something out of Dungeons and Dragons and it&#39;s tied to his hand. So, one of the</p>
<p>Dave! (01:28:24.334)
And then the other kid is like, here, just take my magic string knocker or whatever the fuck he calls it. It&#39;s just like a piece of wood.</p>
<p>Bryan! (01:28:29.957)
it&#39;s a yeah, it&#39;s it&#39;s basically it&#39;s a it&#39;s a stick with like a rag attached to it so that you hold it out in front of you and when you come across one of these invisible strings, it, you know, you you can see it move and like, you know, drag the thing over the strings. So that&#39;s that&#39;s what it is. That&#39;s you know, ingenious. Why this kid is</p>
<p>Dave! (01:28:46.702)
Yeah, I mean, this is a children&#39;s movie, but the implications of all of this are... chilling.</p>
<p>Bryan! (01:28:50.981)
to use this delightful device uses it to go to the bathroom Like I don&#39;t I don&#39;t yeah but get like to detect the strings. but Connie now finds that of Stockholm Syndrome. And</p>
<p>Dave! (01:29:01.71)
Yeah, what do you do in that case?</p>
<p>Dave! (01:29:15.342)
And I...</p>
<p>I have no idea what I was going to say. I am bewildered by the end of this movie.</p>
<p>Bryan! (01:29:20.165)
Yeah. Right. So, the senior wakes up and he catches Connie and and then the chase</p>
<p>Dave! (01:29:29.23)
who then throws giant darts at him for some reason?</p>
<p>Bryan! (01:29:32.421)
Yeah. Oh, but but did you notice why the dartboard is there? Cuz there&#39;s a board and the senior throws them at a picture of a person. That person is the principal from the school and it&#39;s like a head to toe printout of her like full one to one size.</p>
<p>Dave! (01:29:46.67)
So that means that the senior had to have this giant life size British lady thing printed out. He then had to either purchase or make giant darts. Now, why wouldn&#39;t you just use regular sized darts? But the giant darts also have the magic hair on.</p>
<p>Bryan! (01:30:00.133)
Yup.</p>
<p>Yeah, well, because it&#39;s not it&#39;s not.</p>
<p>Yeah, it&#39;s not cartoony or whimsical enough to just...</p>
<p>Dave! (01:30:09.646)
So they make magical paint brushes and giant hair darts.</p>
<p>Bryan! (01:30:13.701)
Yeah. Yeah. For fun. So, uh let me find my space here. Yes. Yes. So, yeah. Cuz Connie is now kept hung in a net and he weaves this story about his dad being dead in a plane crash and he wants the senior to be his father. I know but but that turns out to also be a lie. So, yeah. So, he he begs</p>
<p>Dave! (01:30:19.382)
This is how Connie gets himself out of it. He tells him he wants him to be his dad.</p>
<p>Dave! (01:30:31.534)
Well, it&#39;s about fucking time someone&#39;s parents died.</p>
<p>Dave! (01:30:37.678)
Yep.</p>
<p>Dave! (01:30:43.15)
And the senior says, I&#39;ve never had children, only a dog. And I wrote, I think that&#39;s probably for the best. This guy is dangerous.</p>
<p>Bryan! (01:30:49.445)
the</p>
<p>Dave! (01:30:54.574)
Yeah, that he stole with sleepy ice cream. Jesus Christ.</p>
<p>Bryan! (01:30:58.021)
Yeah. Yeah. Yeah. So but this makes this causes his</p>
<p>Dave! (01:31:03.374)
I mean, how, you can&#39;t have the mother be dead, but you can have the senior be a child drugging rapist.</p>
<p>Bryan! (01:31:09.189)
the same. Yep. He&#39;s but this change and so now, Connie, brushes to paint a real Yep. With one of Michael&#39;s</p>
<p>Dave! (01:31:18.958)
Yep, he is a whimsical kidnapper now.</p>
<p>And in an adorable old man type performance.</p>
<p>Bryan! (01:31:27.045)
Yeah. Oh, yeah. Right. Because there&#39;s like a dance involved and he&#39;s he&#39;s all like happy and like</p>
<p>Dave! (01:31:30.286)
Yeah. And they&#39;re all laughing and living it up. And it&#39;s like, this is the problem with kids. They&#39;re fucking stupid. 10 minutes ago, this guy drugged and kidnapped you and forced you to make horrible hair paint brushes. Now you think he&#39;s the coolest thing ever.</p>
<p>Bryan! (01:31:43.333)
Yeah</p>
<p>Yeah, cuz he does a fan, like a like a funny little dance.</p>
<p>Dave! (01:31:49.646)
Yeah. Now, is it a great moment? I- it&#39;s a little Marcel Marceau for my taste, but I guess. It wouldn&#39;t win me over if he was my kidnapper.</p>
<p>Bryan! (01:31:55.109)
Yeah. Yeah. Yeah. He, I mean, you would have to be like, really, you, like, it would be Stockholm Syndrome. Like, you are fully convinced that this is like your fault or something and that you&#39;ve got to protect him. But what he does is he ends up painting the spooky old house and then Connie has him like go into it and um</p>
<p>Dave! (01:32:04.407)
Hey.</p>
<p>Dave! (01:32:19.566)
and he explains this is where we kind of learn about the fright. I have a lot of questions.</p>
<p>Bryan! (01:32:23.013)
Yeah. So also this is like a big, this is a big like special effect scene too.</p>
<p>Dave! (01:32:27.95)
Oh yeah, you can tell like 30% of their budget went to this moment. But this is where we learn about the fright. So how does Connie know that it&#39;s called the fright?</p>
<p>Bryan! (01:32:30.501)
Yep.</p>
<p>Bryan! (01:32:35.717)
I believe either Michael, Michael&#39;s dad or the doctor.</p>
<p>Dave! (01:32:40.718)
And what is the fright? Because I thought it was just these two old dead drunks.</p>
<p>Bryan! (01:32:45.413)
like a pathological thing that you can get when you&#39;re scared and but like but now we learn but it&#39;s like a curse because what ends up happening is the senior goes into it and he they&#39;re like, hey, climb up in there and like go in the house and he goes in the house and he has the same exact experience that Michael has where he gets scared and he loses all of his hair but now</p>
<p>Dave! (01:32:54.102)
But all it does is make your hair grow?</p>
<p>Bryan! (01:33:13.957)
the senior has the fright, meaning Michael has like passed the curse on or something like that. This is where we&#39;re just we&#39;re just free associating at this point. Yeah. Yeah.</p>
<p>Dave! (01:33:23.694)
This is basically It Follows, but without the sec. Or maybe, maybe just like It Follows, I don&#39;t know.</p>
<p>Bryan! (01:33:30.757)
So, so...</p>
<p>like Michael. So, no, Michael is now like free of this curse. They get up. The senior comes in and he gets up and now he has no hair. He kind of looks like Bryan Cranston in Breaking Bad and they, you know, they do the whole like Scooby Doo chase scene where they&#39;re running down hallways and then out of doors and stuff.</p>
<p>Dave! (01:33:45.646)
He does.</p>
<p>Dave! (01:33:51.822)
Oh yeah, the only thing it&#39;s missing is like a fucking strawberry alarm clock soundtrack or something. I don&#39;t. It is a real loop loopy moment.</p>
<p>Bryan! (01:33:59.973)
Yeah, there&#39;s there&#39;s a lot of this like sort of them trying to escape and they they get end up getting cornered by the the senior who&#39;s about to pounce when in runs Michael&#39;s dad and the rabbit and the doctor. That&#39;s right. Yeah.</p>
<p>Dave! (01:34:12.526)
Oh, cause Susie eventually picks up the trail of the sugar. Which thank fucking God, cause otherwise these kids would be murdered. Cause she&#39;s the only one doing anything.</p>
<p>Bryan! (01:34:20.293)
And that and this movie would just this movie would just keep <em>**</em> going on and on and on. But anyways, dad shows up.</p>
<p>Dave! (01:34:26.286)
They take him down and the father says, don&#39;t get his pants dirty, that&#39;s his only pair. Or no, the rabbit says that. And I thought, that is a really weird thing to say. Also, how do you know that?</p>
<p>Bryan! (01:34:33.989)
Yes. I don&#39;t.</p>
<p>Bryan! (01:34:40.613)
I don&#39;t know. By this point, I&#39;m just like, by this, by this point, I&#39;m just like, can we, can we just get on with this? Yeah. So, yes, but they do and then the senior gets arrested and reunited back at home. Michael&#39;s mom comes home and the family definitely not dead. Definitely alive. She comes home. Family is fully reunited. Roll the <em>**</em> credits.</p>
<p>Dave! (01:34:43.214)
I don&#39;t know how many pairs of pants you own. Why does he know that?</p>
<p>Seriously, wrap this shit up, guys.</p>
<p>Dave! (01:34:58.638)
Thank God! Look everybody, she&#39;s definitely not dead!</p>
<p>Dave! (01:35:09.23)
Well, you forgot the part where we get the, uh, the winos again.</p>
<p>And he goes, because he goes back into the painting. And the woman, he&#39;s like, why was I so afraid of you? And she&#39;s like, what are you talking about? I&#39;m a kooky old crazy lady ghost. And then he&#39;s like, no, you&#39;re not. You&#39;re just I&#39;m like, well, she did cause you to lose all your hair. And she&#39;s kind of been wicked mean to you all along.</p>
<p>Bryan! (01:35:15.577)
That&#39;s right, because he goes into the painting and he faces him.</p>
<p>Bryan! (01:35:26.309)
Yeah.</p>
<p>Bryan! (01:35:31.461)
Yeah. If it&#39;s almost as like, it&#39;s almost as though they&#39;re like trying to sort of sell us on this idea that he has learned some lesson, but what that lesson is is never is never outlined.</p>
<p>Dave! (01:35:42.926)
That hey, maybe old dead winos aren&#39;t as scary as you thought they were. They&#39;re just the, they&#39;re the old dead winos you met along the way. That&#39;s life right there. And then the guy says something, the homeless guy says something, but the lines are 80 yard and his mouth doesn&#39;t even move at all.</p>
<p>Bryan! (01:35:50.285)
Yeah, perhaps the dead winos are the</p>
<p>Bryan! (01:35:58.917)
Yeah, yeah.</p>
<p>Dave! (01:36:01.774)
And we never learned what happened to the old gross kidnapper. That&#39;s just the end of the movie. And I thought, wow, that was a movie for kids.</p>
<p>Bryan! (01:36:07.141)
That&#39;s just the end of the movie.</p>
<p>Yeah, Celine Dion plays us off.</p>
<p>fucking Christ. Ah.</p>
<p>Dave! (01:36:19.438)
So is it a horror movie? I don&#39;t know, but I don&#39;t know what else it is either.</p>
<p>Bryan! (01:36:22.874)
I think I think that it is definitely unsettling in enough places in enough ways to definitely like I understand why Severin picked up on it because it&#39;s definitely got that like it&#39;s uncanny. It&#39;s it&#39;s weird to a fault. You know, just the whole probably not. It&#39;s probably like the stuff that vinegar syndrome is picking up.</p>
<p>Dave! (01:36:34.126)
Mm-hmm.</p>
<p>Dave! (01:36:40.014)
And let&#39;s be honest, that license can&#39;t be that expensive.</p>
<p>Bryan! (01:36:46.181)
but uh yeah good god am i am i glad we&#39;re past this one because i gotta tell you uh i i i&#39;m not i&#39;m not a fan i&#39;m glad i saw it and uh also i mean as far as like blu-rays go you could do a lot worse like severance release of it is really quite good yeah yeah</p>
<p>Dave! (01:37:04.75)
Yeah, I mean, will I watch it again? Probably.</p>
<p>Bryan! (01:37:09.381)
it&#39;s one of those ones that like if I&#39;m gonna have some somebody&#39;s gonna be like what&#39;s a really fucked up movie that&#39;s not like a fucking blood guts horror movie I&#39;d probably show them that</p>
<p>Dave! (01:37:18.862)
Yeah, I mean, it&#39;s like that or magnolia. Those are your options.</p>
<p>Bryan! (01:37:22.309)
Yeah, yep. So what&#39;s next?</p>
<p>Dave! (01:37:26.286)
Oh, man. What is next is we&#39;re we&#39;re going to be celebrating as its national Hispanic heritage. I believe that is the technical term. I think it also might be somewhat controversial name, but I am not going to get involved in that fracas. Instead, what I will do is I will celebrate my favorite Mexican horror film. Don&#39;t panic.</p>
<p>Bryan! (01:37:50.053)
Yeah. Oh, man. We are going to put Ruben Galindo Jr. on a pedestal and really, really let you know just how wonderful this movie is. Yep. This is one that we&#39;ve been looking forward to for a while. And man, am I glad we found a reason to get it on the schedule. So, yep, stick with us. We&#39;ll see you. We&#39;ll see you next time with Don&#39;t Panic.</p>
<p>Dave! (01:37:55.726)
I love this movie so fucking much. It makes me so happy.</p>
<p>Dave! (01:38:13.518)
Hey-o!</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/dont-panic">Next Episode</Link></li>
                        <li><Link to="/episodes/burnt-offerings">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)